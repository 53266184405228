import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import OrderActions from './OrderActions';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ProductDataType } from '@crema/types/models/ecommerce/EcommerceApp';
import { ellipsisLines } from '@crema/helpers/StringHelper';
import {Button} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  '&:first-of-type': {
    paddingLeft: 20,
  },
  '&:last-of-type': {
    paddingRight: 20,
  },
}));

type TableItemProps = {
  data: any;
  keys: string[],
  edit_fun: any, edit_name: string,
  delete_fun: any, delete_name: string,
  view_name: string
};

const TableItem = ({ data, keys, edit_fun, delete_fun, edit_name = "Edit User", delete_name = "Delete User", view_name="View User" }: TableItemProps) => {
  const navigate = useNavigate();
  const getPaymentStatusColor = () => {
    switch (data.inStock) {
      case true: {
        return '#43C888';
      }
      case false: {
        return '#F84E4E';
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow key={data._id} className='item-hover'>
        {keys.map((key) => {
          if(key === "Image" || key === "Image Mobile") {
            return <><StyledTableCell align='left' ><img style={{height: "10vh"}} src={data[key]}/></StyledTableCell></>
          }
          if(key === "Seller Permit") {
            return <><StyledTableCell align='left' ><a href={data[key]}>Download</a></StyledTableCell></>
          }
          if(key === "Actions") {
            return <TableCell key={"menu"} align='right'>
              <IconButton
                  aria-controls='alpha-menu'
                  aria-haspopup='true'
                  onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                  id='alpha-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
              >
                {view_name ? <MenuItem style={{ fontSize: 14 }} onClick={() => edit_fun(data)}>
                  {view_name}
                </MenuItem> : ""}
                <MenuItem style={{ fontSize: 14 }} onClick={() => edit_fun(data, true)}>
                  {edit_name}
                </MenuItem>
                <MenuItem style={{ fontSize: 14 }} onClick={() => delete_fun(data)}>
                  {delete_name}
                </MenuItem>
              </Menu>
            </TableCell>
          }
            return <><StyledTableCell align='left' dangerouslySetInnerHTML={{ __html: data[key] }} /></>
        })}


    </TableRow>
  );
};

export default TableItem;
