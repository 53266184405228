import React, { useEffect, useMemo, useState } from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import { useAppDispatch, useAppSelector } from '../../../toolkit/hooks';
import { getCustomers } from '../../../toolkit/actions';
import jwtAxios, { setAuthToken } from "../../../@crema/services/auth/jwt-auth";
import { openSnackbar } from "../../../toolkit/actions/snackbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContactDetail from "../../apps/Contact/ContactDetail";
import { Button, Hidden, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import axios from 'axios';
import dayjs from 'dayjs';

const PendingCases = () => {
  const phase = "all";
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const [customerCount, setCustomerCount] = useState(60)
  const [page, setPage] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false)
  const [isShowDetail, onShowDetail] = useState(false);
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sellers = searchParams.get('seller');
  const report = !!searchParams.get('report');

  const rowsPerPage = 10;

  const [selectedContact, setSelectedContact] = useState(
    {}
  );
  const [search, setSearch] = useState("");
  const onViewContactDetail = (contact) => {
    setSelectedContact(contact);
    onShowDetail(true);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Copied to clipboard successfully!');
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  };



  const [customerList, setCustomerList] = useState([])
  const [customerCSV, setCustomerCSV] = useState(null)
  const customers = useMemo(() => {
    if (customerList.length > 0) {
      return customerList
        .filter(customer => customer.firstName.toLowerCase().includes(search.toLowerCase()))
        .map(customer => {
          let customerDetails = {}
          if (report) {
            customerDetails = {
              "Credential ID": customer.credentialID,
              "Name": `${customer.firstName} ${customer.lastName}`,
              "Status": `<span style="color: ${customer.status === "active" ? 'blue' : 'red'}">${customer.status}</span>`,
              "Email": customer.email,
              "Company Name": customer.companyName,
              "State": customer.state,
              "City": customer.city,
              "Zip Code": customer.zipCode,
              "Address": customer.address,
              "Phone Number": customer.phoneNumber,
              "Employer ID": customer.employerId,
              "Seller Permit": customer.sellerPermit,
              "Hear About Us": customer.hearAboutUs,
              "Selling Platform": customer.sellingPlatform.join(", "),
            };
          } else {
            customerDetails = {
              "Name": `${customer.firstName} ${customer.lastName}`,
              "Credits": customer.credits,
              "Status": `<span style="color: ${customer.status === "active" ? 'blue' : 'red'}">${customer.status}</span>`,
              "Email": customer.email,
              "Company Name": customer.companyName,
              "Phone Number": customer.phoneNumber,
              "Seller Permit": customer.sellerPermit,
            };
          }
          return customerDetails;
        });
    } else {
      return [];
    }
  }, [customerList, search]);


  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = () => {
    let config = {
      method: 'get',
      url: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/api/case',
      headers: {
        'apikey': 'c747b51cc1b18e6e9f4fdd72d6ada6c8',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    axios.request(config)
      .then((response) => {
        const completedCases = response.data.data.caseList.filter(caseItem => (caseItem.caseStatus !== 'Completed' && caseItem.name !== "" && caseItem.agentid !== ''));
        const sortedCases = completedCases.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        // console.log('completedCases', sortedCases)
        setTableData(sortedCases)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const showCustomer = (data, edit) => {
    const email = data.Email
    const customerToShow = customerList.filter(cus => cus.email === email);
    console.log(customerToShow[0])
    if (report) {
      onViewContactDetail(customerToShow[0]);
    } else {
      const goUrl = edit ? '/edituser' : '/user'

      navigate(goUrl, {
        state: {
          contact: customerToShow
        }
      })
    }
  }
  const onPageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    // dispatch(getCustomers(search, page));
  }, [dispatch, search, page]);

  const onSearchCustomer = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const updateTable = () => {
    let searchURL = `user?phase=${phase}&page=${page + 1}&search=${search}`
    if (sellers) {
      searchURL = `user?phase=${phase}&page=${page + 1}&search=${search}&seller=${sellers}`
    }
    let searchCSVURL = `usercsv?phase=${phase}&page=${page + 1}&search=${search}`
    if (sellers) {
      searchCSVURL = `usercsv?phase=${phase}&page=${page + 1}&search=${search}&seller=${sellers}`
    }

    jwtAxios.get(searchURL).then((response) => {
      setCustomerList(response.data.users);
      setLoadingTable(false);
      setCustomerCount(response.data.total)
      console.log("loading the customers list", response.data)
    });
    jwtAxios.get(searchCSVURL).then((response) => {
      setCustomerCSV(response.data);
      console.log("loading the users csv", response.data)
    });

  }

  useEffect(() => {
    // if(search.length > 2 || search.length === 0) {
    //     updateTable()
    // }
  }, [search, phase, page, sellers])


  const delete_fun = (data) => {
    console.log(data)

    jwtAxios.put('user', { credentialID: data["_id"], status: "not approved" }).then((response) => {
      console.log("done not approve", response.data)
    });
    onShowDetail(false);
    dispatch(openSnackbar('Application denied of user: ' + data.firstName, 'success'));
    updateTable()
  }

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleConfirmDelete = (data) => {
    setUserToDelete(data);
    setOpenConfirmDialog(true);
  };

  const handleDelete = () => {
    const id = userToDelete.Email;
    console.log(customerList)
    const customerToShow = customerList.filter(cus => cus.email === id);
    if (userToDelete) {
      jwtAxios.delete('user/' + customerToShow[0]["_id"]).then((response) => {
        console.log("done not approve", response.data);
        onShowDetail(false);
        dispatch(openSnackbar('Application denied of user: ' + userToDelete.Name, 'success'));
        updateTable();
      });
    }
    setOpenConfirmDialog(false);
    setUserToDelete(null);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    setUserToDelete(null);
  };

  const edit_fun = (data, edit = false) => {
    const id = data.Email;
    console.log(customerList)
    const customerToShow = customerList.filter(cus => cus.email === id);
    console.log(customerToShow[0])
    if (report) {
      onViewContactDetail(customerToShow[0]);
    } else {
      const goUrl = edit ? '/edituser' : '/user'

      navigate(goUrl, {
        state: {
          contact: customerToShow
        }
      })
    }
  }

  const filteredCases = tableData.filter(caseItem => {
    // caseItem.name.toLowerCase().includes(search.toLowerCase()) ||
    // caseItem.licensePlate.toLowerCase().includes(search.toLowerCase())
    const searchTerm = search.toLowerCase();
    return (
      caseItem.name.toLowerCase().includes(searchTerm) ||
      caseItem.licensePlate.toLowerCase().includes(searchTerm) ||
      caseItem.caseId.toLowerCase().includes(searchTerm)
    )
  });

  const paginatedCases = filteredCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <AppsContainer
        title={"Pending Cases"}
        fullView
      >
        <AppsHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
            }}
          >
            <AppSearchBar
              iconPosition='right'
              overlap={false}
              onChange={onSearchCustomer}
              placeholder={messages['common.searchHere']}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
              }}
            >
              <Button onClick={() => { console.log("helo") }}>Download CSV</Button>
              <Hidden smDown>
                <AppsPagination
                  rowsPerPage={rowsPerPage}
                  count={filteredCases.length}
                  page={page}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </Box>
          </Box>
        </AppsHeader>

        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <Table stickyHeader className='table'>
            <TableHead>
              <TableCell>Case ID</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Vehicle Name</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>License Plate</TableCell>
              <TableCell>Address</TableCell>
            </TableHead>
            <TableBody>
              {paginatedCases.map((data) => (
                <TableRow>
                  {/* {data.caseStatus !== "Completed" && data.name !== "" && data.agentid !== '' ? <> */}
                  <TableCell>{data.caseId}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <a
                        href={`https://smartinspect.ai/verification/${data._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: 8 }}
                      >
                        Link
                      </a>
                      <IconButton
                        size="small"
                        onClick={() =>
                          copyToClipboard(`https://smartinspect.ai/verification/${data._id}`)
                        }
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>{data.name}</TableCell>
                  <TableCell>{data.number}</TableCell>
                  <TableCell>{data.email}</TableCell>
                  <TableCell align='left'>
                    {dayjs(data.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>{data.vehicleName}</TableCell>
                  <TableCell>{data.color}</TableCell>
                  <TableCell>{data.licensePlate}</TableCell>
                  <TableCell>{data.address}</TableCell>

                  {/* </> : null} */}
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </AppsContent>

        <Hidden smUp>
          <AppsPagination
            rowsPerPage={rowsPerPage}
            count={filteredCases.length}
            page={page}
            onPageChange={onPageChange}
          />
        </Hidden>
      </AppsContainer>

      <ContactDetail
        selectedContact={selectedContact}
        isShowDetail={isShowDetail}
        onShowDetail={onShowDetail}
        delete_fun={delete_fun} delete_name={"Decline"} edit_name={"Approve"} edit_fun={edit_fun}
      />

      <AppInfoView />

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user ({userToDelete?.Name})?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PendingCases;
