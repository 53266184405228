import React, { useState, useEffect } from 'react';
import Papa from 'papaparse'; // Library for parsing CSV
import { Fonts } from '@crema/constants/AppEnums';
import { Box, Button, Grid, Slide, TextField } from '@mui/material';
import jwtAxios, {setAuthToken} from "../../../@crema/services/auth/jwt-auth";
import {openSnackbar} from "../../../toolkit/actions/snackbar";

function UploadProduct() {
    const [file, setFile] = useState(null);
    const [productData, setProductData] = useState([]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const generateSampleCSV = () => {
        const csvData = `ASIN,INCREMENTAL QTY,Price,MOQ or No less than $400,SKU
            B00002X202,36,2.09,400.00,12
            B00002X20D,36,4.51,400.00,10`;

        const blob = new Blob([csvData], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'sampleProducts.csv';
        link.click();
    };

    const handleSubmit = () => {
        if (!file) {
            alert('Please select a file.');
            return;
        }

        Papa.parse(file, {
            complete: (result) => {
                console.log(result)
                const formattedData = result.data.map((row) => ({
                    asin: row["ASIN"],
                    incrementalQty: parseInt(row["INCREMENTAL QTY"]),
                    moq: parseFloat(row["MOQ or No less than $400"]),
                    price: parseFloat(row["Price"]),
                    sku: row["SKU"],
                }));

                formattedData.forEach(item => {
                    item.actualMoq = Math.ceil(item.moq / item.price);
                });

                setProductData(formattedData);
            },
            header: true,
        });
    };

    useEffect(() => {
        try {


            productData.map(async (product) => {
                const response = await jwtAxios.post('autoProduct', product);
                console.log('Form submitted:',product, response );
            })



        }catch (e) {
            console.log(e)
        }
    }, [productData])

    return (
        <>
            <Box
                component='h2'
                sx={{
                    fontSize: 16,
                    color: 'text.primary',
                    fontWeight: Fonts.SEMI_BOLD,
                    mb: {
                        xs: 2,
                        lg: 4,
                    },
                }}
            >
                {'Upload Case'}
            </Box>
            <Slide direction='right' in mountOnEnter unmountOnExit>
                <Grid item xs={12} lg={8}>
                    <TextField
                        className='mt-8 mb-16 mx-6'
                        label='File'
                        type='file'
                        id='file'
                        name='file'
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={handleFileChange}
                    />

                    <br /><br />

                    <Button
                        sx={{
                            display: 'block',
                            minWidth: 100,
                        }}
                        color='primary'
                        variant='contained'
                        type='button'
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>

                    <br /><br />
                    
                    <Button
                        sx={{
                            display: 'block',
                            minWidth: 100,
                        }}
                        color='primary'
                        variant='contained'
                        type='button'
                        onClick={generateSampleCSV}
                    >
                        Download Template
                    </Button>

                    {/* Display product data */}
                    <ul>
                        {productData.map((product, index) => (
                            <li key={index}>
                                ASIN: {product.asin}, Incremental Qty: {product.incrementalQty}, Price: {product.price}, MOQ: {product.moq}, Actual MOQ: {product.actualMoq}
                            </li>
                        ))}
                    </ul>
                </Grid>
            </Slide>
        </>
    );
}

export default UploadProduct;
