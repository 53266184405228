import React, { useRef, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import uploadFile from 's3/s3.upload';
import jwtAxios from '@crema/services/auth/jwt-auth';
import Confetti from 'react-confetti';

const VideoRecording = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null); // Reference to the video element
  const mediaRecorderRef = useRef<MediaRecorder | null>(null); // Reference to the MediaRecorder
  const [isRecording, setIsRecording] = useState(false); // State to track if recording is in progress
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]); // State to store recorded video chunks
  const navigate = useNavigate();
  const { id } = useParams();
  const [success, setSuccess] = useState(false);

  // Function to start recording
  const startRecording = async () => {
    try {
      const isMobile = /android|iPad|iPhone|iPod/i.test(navigator.userAgent);
      const stream = await navigator.mediaDevices.getUserMedia({
        // video: { facingMode: 'environment' }, // Use the back camera
        video: {
          facingMode: isMobile ? { exact: "environment" } : "user",
        },
        audio: true, // Capture audio
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream; // Set video stream to the video element
        videoRef.current.play(); // Start playing the video stream
      }

      // const mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });

      // Check for supported mime types
      const mimeType = 'video/webm; codecs=vp8'; // Default mime type
      const isSupported = MediaRecorder.isTypeSupported(mimeType);

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: isSupported ? mimeType : 'video/mp4' // Fallback to mp4 if webm isn't supported
      });

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]); // Append recorded data
        }
      };

      mediaRecorder.start(); // Start recording
      setIsRecording(true); // Update recording state
    } 
  //   catch (error) {
  //     console.error('Error accessing camera or microphone:', error);
  //     alert(`Error: ${error.message}`);
  //   }
  // };

  // Added new lines
  catch (error) {
    if (error.name === 'OverconstrainedError') {
      // Try to access the user camera as a fallback
      try {
        const fallbackConstraints = {
          video: {
            facingMode: "user", // Switch to front camera
          },
          audio: true,
        };
        
        const stream = await navigator.mediaDevices.getUserMedia(fallbackConstraints);
        
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }

        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            setRecordedChunks((prev) => [...prev, event.data]);
          }
        };

        mediaRecorder.start();
        setIsRecording(true);
      } catch (fallbackError) {
        console.error('Error accessing user camera:', fallbackError.message);
        alert(`Error: ${fallbackError.message}`);
      }
    } else {
      console.error('Error accessing camera or microphone:', error.message);
      alert(`Error: ${error.message}`);
    }
  }
};

  // Function to stop recording
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop(); // Stop recording
      setIsRecording(false); // Update recording state
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach((track) => track.stop()); // Stop all tracks
      }
    }
  };

  const updateVideoUrl = async (id: string, video: string) => {
    try {
      await jwtAxios.put(`/api/case/${id}`, { video });
      console.log('Video URL updated successfully');
    } catch (error) {
      console.error('Error updating video URL:', error);
      throw error;
    }
  };

  // Function to handle saving the recorded video
  // const saveRecording = () => {
  //   const blob = new Blob(recordedChunks, { type: 'video/webm' });
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.style.display = 'none';
  //   a.href = url;
  //   a.download = 'recorded_video.webm';
  //   document.body.appendChild(a);
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // };
  const saveRecording = async () => {
    const blob = new Blob(recordedChunks, { type: 'mp4' });
    const url = URL.createObjectURL(blob);

    const key = `images/${id}/${Date.now()}_mp4`; // S3 key for the video
    try {
      const fileUrl = await uploadFile(key, blob, 'mp4');
      console.log("Video uploaded to:", fileUrl);

      await updateVideoUrl(id, fileUrl);
      // navigate('/'); 
      setSuccess(true);
    } catch (error) {
      console.error("Error uploading video:", error);
    } finally {
      URL.revokeObjectURL(url); // Clean up the object URL
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        textAlign: 'center',
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {success ? (
        <>
          <Typography variant="h5" fontSize="24px" fontWeight="600" sx={{ mb: 3 }}>
            Video Recording Complete!
          </Typography>
          <Confetti />
          <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => navigate('/')}>
            Back to Evaluation
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h5" fontSize="24px" fontWeight="600" sx={{ mb: 3 }}>
            Video Recording
          </Typography>
          <Box
            sx={{
              width: '100%',
              maxWidth: '350px',
              borderRadius: '12px',
              overflow: 'hidden',
              margin: '0 auto',
              mt: 2,
              position: 'relative',
            }}
          >
            <video ref={videoRef} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} autoPlay muted />
          </Box>
          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button variant="contained" color="primary" onClick={startRecording} disabled={isRecording}>
              Start Recording
            </Button>
            <Button variant="contained" color="secondary" onClick={stopRecording} disabled={!isRecording}>
              Stop Recording
            </Button>
          </Box>
          {recordedChunks.length > 0 && (
            <Button variant="contained" color="success" onClick={saveRecording} sx={{ mt: 2 }}>
              Save Recording
            </Button>
          )}
          <Button variant="outlined" sx={{ mt: 3 }} onClick={() => navigate('/')}>
            Back to Evaluation
          </Button>
        </>
      )}
    </Container>
  );
};

export default VideoRecording;
