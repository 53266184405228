import React, { useEffect, useState } from 'react';
import AppGridContainer from '@crema/components/AppGridContainer';
import { Fonts } from '@crema/constants/AppEnums';
import { Box, Button, Grid, Slide, TextField, Autocomplete } from '@mui/material';
import AppScrollbar from '@crema/components/AppScrollbar';
import AppCard from '@crema/components/AppCard';
import jwtAxios, { setAuthToken } from "@crema/services/auth/jwt-auth/index"

const cats = [
    {
        "categoryName": "Wall stickers from top brands",
        "status": "",
        "_id": "42094087031",
        "createdOn": "2024-03-26T11:02:46.712Z",
        "__v": 0
    },
    {
        "categoryName": "Football Fever",
        "status": "",
        "_id": "4841805031",
        "createdOn": "2024-03-26T11:04:53.787Z",
        "__v": 0
    }
];

function SubCategory() {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [subCategoryName, setSubCategoryName] = useState('');
    const [tag, setTag] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const response = await jwtAxios.post('subCategory', {
                parentCategories: selectedCategories.map(cat => cat._id),
                subCategoryName,
                tag,
                status,
            });
            console.log(response.data);
            setSelectedCategories([])
            setSubCategoryName("")
            setStatus("")
            setTag("")
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Box
                component='h2'
                sx={{
                    fontSize: 16,
                    color: 'text.primary',
                    fontWeight: Fonts.SEMI_BOLD,
                    mb: {
                        xs: 2,
                        lg: 4,
                    },
                }}
            >
                {'Sub Category'}
            </Box>
            <Slide direction='right' in mountOnEnter unmountOnExit>
                <Grid item xs={12} lg={8}>
                    <AppScrollbar style={{ height: '700px' }}>
                        <AppCard>
                            <form onSubmit={handleSubmit}>
                                <Autocomplete
                                    multiple
                                    label='Parent Categories'
                                    id='parentCategories'
                                    name='parentCategories'
                                    options={cats}
                                    getOptionLabel={(option) => option.categoryName}
                                    value={selectedCategories}
                                    onChange={(event, newValue) => {
                                        setSelectedCategories(newValue);
                                    }}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Parent Categories'
                                            placeholder='Select Parent Categories'
                                        />
                                    )}
                                />

                                <br />

                                <TextField
                                    className='mt-8 mb-16 mx-6'
                                    label='SubCategory Name'
                                    id='subCategoryName'
                                    name='subCategoryName'
                                    variant='outlined'
                                    fullWidth
                                    value={subCategoryName}
                                    onChange={(e) => setSubCategoryName(e.target.value)}
                                />

                                <br /><br />

                                <TextField
                                    className='mt-8 mb-16 mx-6'
                                    label='Tag'
                                    id='tag'
                                    name='tag'
                                    variant='outlined'
                                    fullWidth
                                    value={tag}
                                    onChange={(e) => setTag(e.target.value)}
                                />

                                <br /><br />

                                <TextField
                                    className='mt-8 mb-16 mx-6'
                                    label='Status'
                                    id='status'
                                    name='status'
                                    variant='outlined'
                                    fullWidth
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                />

                                <br />
                                <br />

                                <Button
                                    sx={{
                                        display: 'block',
                                        minWidth: 100,
                                    }}
                                    color='primary'
                                    variant='contained'
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </form>
                        </AppCard>
                    </AppScrollbar>
                </Grid>
            </Slide>
        </>
    );
}

export default SubCategory;
