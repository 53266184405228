import React, { useEffect, useMemo, useState } from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { Button, Hidden, TableRow } from '@mui/material';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import OrderTable from '../PendingOrders/OrderTable';
import { useAppDispatch, useAppSelector } from '../../../toolkit/hooks';
import { getRecentOrders } from '../../../toolkit/actions';
import jwtAxios from "../../../@crema/services/auth/jwt-auth";
import { useNavigate } from "react-router-dom";
import DateFormat from "../../../@crema/helpers/DateFormat";
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import axios from 'axios';

const AgentsInActive = () => {
    const { messages } = useIntl();
    const dispatch = useAppDispatch();
    const [orders, setRecentOrders] = useState([])
    const [page, setPage] = useState(0);
    const [search, setSearchQuery] = useState('');
    const [totalOrders, setTotalOrders] = useState(0)
    const [tableData, setTableData] = useState([]);
    const recentOrders = useMemo(() => {
        if (orders.length > 0) {
            console.log("wut?")
            return orders
                .map(cats => ({ id: cats._id, orderID: cats.orderID, creditsUsed: cats.creditsUsed, companyName: cats.companyName, credentialID: cats.credentialID, "product": cats.orderDetails.map((orderrr) => { return `${orderrr.name} - ${orderrr.sku} - ${orderrr.qty}\n` }), "date": dayjs(cats.createdOn).format('MM/DD/YYYY HH:mm:ss'), "status": cats.status, "price": cats.ammount, "notes": cats.notes }));
        } else {
            return []
        }
    }, [orders, search])

      const onPageChange = (value) => {
        setPage(value);
      };
    const updateTable = () => {
        const url = `orders?status=Shipped&page=${page + 1}`
        jwtAxios.get(url).then((response) => {
            setRecentOrders(response.data.orders);
            setTotalOrders(response.data.totalOrders)
            // setLoadingTable(false)
            console.log("loading the pending order list", response.data)
        });
    }
    useEffect(() => {
        // updateTable()
        fetchData();
    }, [page]);

    //   const onSearchOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchQuery(e.target.value);
    //     setPage(0);
    //   };

    const fetchData = () => {
        let config = {
            method: 'get',
            url: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/api/agents',
            headers: {
                'apikey': 'c747b51cc1b18e6e9f4fdd72d6ada6c8',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        axios.request(config)
            .then((response) => {
                setTableData(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const markAgentAsInActive = (key) => {

        if (window.confirm('Are you sure you want to mark the agent as inactive?')) {
            let data = JSON.stringify({
                "approvalStatus": "Pending",
            });

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/api/agents/' + key._id,
                headers: {
                    'apikey': 'c747b51cc1b18e6e9f4fdd72d6ada6c8',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    alert("Agent Marked InActive Successfully")
                    fetchData()
                })
                .catch((error) => {
                    console.log(error);
                });
        } else { }
    }

    const locate = useNavigate()
    const handleViewOrder = (id) => {
        console.log("the id", id)
        locate("/orders/viewOrder", { state: { id } })
    }
    return (
        <>
            <AppsContainer
                title={'Agents InActive'}
                fullView
            >
                <AppsHeader>
                    <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        width={1}
                        justifyContent='space-between'
                    >
                        <AppSearchBar
                            iconPosition='right'
                            overlap={false}
                            // onChange={(event) =>
                            //     setSearch(event.target.value)
                            // }
                            placeholder={"Search Here"}
                        />
                        {/* <AppSearchBar
                            iconPosition='right'
                            overlap={false}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                onSearchOrder(event)
                            }
                            placeholder={messages['common.searchHere'] as string}
                        /> */}
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            {/*<Button variant='contained' color='primary'>*/}
                            {/*  Add Order*/}
                            {/*</Button>*/}

                            <Hidden smDown>
                                <AppsPagination
                                    rowsPerPage={10}
                                    count={totalOrders}
                                    page={page}
                                    onPageChange={onPageChange}
                                />
                            </Hidden>
                        </Box>
                    </Box>
                </AppsHeader>

                <AppsContent
                    sx={{
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                    }}
                >
                    <Table stickyHeader className='table'>
                        <TableHead>
                            <TableCell>Name</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>IMEI</TableCell>
                            <TableCell>Action</TableCell>
                        </TableHead>
                        <TableBody>
                            {tableData.map((data) => (
                                <TableRow>
                                    {data.approvalStatus === "Approved" ? <>
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell>{data.number}</TableCell>
                                        <TableCell>{data.email}</TableCell>
                                        <TableCell>{data.imei}</TableCell>
                                        <TableCell> <Button
                                            sx={{
                                                display: 'block',
                                                minWidth: 100,
                                            }}
                                            color='primary'
                                            variant='contained'
                                            type='submit'
                                            onClick={() => markAgentAsInActive(data)}
                                        >
                                            InActive
                                        </Button></TableCell>
                                    </> : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </AppsContent>

                <Hidden smUp>
                    <AppsPagination
                        rowsPerPage={10}
                        count={totalOrders}
                        page={page}
                        onPageChange={onPageChange}
                    />
                </Hidden>
            </AppsContainer>
            <AppInfoView />
        </>
    );
};

export default AgentsInActive;
