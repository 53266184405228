import React, {useEffect, useMemo, useState} from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { Button, Hidden } from '@mui/material';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import OrderTable from './OrderTable';
import { useAppDispatch, useAppSelector } from '../../../toolkit/hooks';
import { getRecentOrders } from '../../../toolkit/actions';
import jwtAxios, {setAuthToken} from "../../../@crema/services/auth/jwt-auth";
import {useNavigate} from "react-router-dom";
import DateFormat from "../../../@crema/helpers/DateFormat";
import dayjs from 'dayjs';
import { useParams, useSearchParams } from 'react-router-dom';

const PendingOrders = () => {
  let { state } = useParams();
  state = state.replace('*', '');

  const [searchParams] = useSearchParams();

  // Access query parameters
  const refundMethod = searchParams.get('refundMethod');

  const title = refundMethod ? refundMethod + " Orders" : "Pending Orders"
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const [orders, setRecentOrders] = useState([])
  const [totalOrders, setTotalOrders] = useState(0)
  const [page, setPage] = useState(0);
  const [search, setSearchQuery] = useState('');

  const recentOrders = useMemo(() => {
    if (orders.length > 0) {
      console.log("wut?")
            return orders
          .map(cats => ({ id: cats._id, orderID: cats.orderID, creditsUsed: cats.creditsUsed, "date": dayjs(cats.createdOn).format('MM/DD/YYYY HH:mm:ss'), "product": cats.orderDetails.map((orderrr) => {return `${orderrr.name} - ${orderrr.sku} - ${orderrr.qty}\n`}), companyName: cats.companyName,credentialID: cats.credentialID, "status": cats.status, "price": cats.ammount, "notes": cats.notes }));
    } else {
      return []
    }
  }, [orders, search])

  const locate = useNavigate()
  const handleViewOrder = (id) => {
    console.log(id)

    locate("/orders/cart", {state: {id, checkout: true}})
  }
  const updateTable = () => {
    let url = `orders?status=${state}&page=${page+1}`
    if(refundMethod) {
      url = url+`&refundMethod=${refundMethod}`
    }
    jwtAxios.get( url ).then((response) => {
      setRecentOrders(response.data.orders);
      setTotalOrders(response.data.totalOrders)
      // setLoadingTable(false)
      console.log("loading the pending order list", response.data)
    });
  }
  const onPageChange = (event: any, value: number) => {
    setPage(value);
  };
  useEffect(() => {
    updateTable()
  }, [page, refundMethod, state]);


  const onSearchOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setPage(0);
  };
  return (
    <>
      <AppsContainer
        title={title}
        fullView
      >
        <AppsHeader>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            width={1}
            justifyContent='space-between'
          >
            <AppSearchBar
              iconPosition='right'
              overlap={false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSearchOrder(event)
              }
              placeholder={messages['common.searchHere'] as string}
            />
            <Box display='flex' flexDirection='row' alignItems='center'>
              {/*<Button variant='contained' color='primary'>*/}
              {/*  Add Order*/}
              {/*</Button>*/}

              <Hidden smDown>
                <AppsPagination
                  rowsPerPage={10}
                  count={totalOrders}
                  page={page}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </Box>
          </Box>
        </AppsHeader>

        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <OrderTable handleViewOrder={handleViewOrder} orderData={recentOrders} />
        </AppsContent>

        <Hidden smUp>
          <AppsPagination
            rowsPerPage={10}
            count={totalOrders}
            page={page}
            onPageChange={onPageChange}
          />
        </Hidden>
      </AppsContainer>
      <AppInfoView />
    </>
  );
};

export default PendingOrders;
