import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Input,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
// import SelectField from './Component/SelectFiled';
import jwtAxios from '@crema/services/auth/jwt-auth';
import uploadFile from 's3/s3.upload';
// import useVahanVehicleDetails from './CustomHooks/useVahanVehicleDetails';
import SelectField from '../SelectFiled';
import useVahanVehicleDetails from '../../CustomHooks/useVahanVehicleDetails';

const CustomInput = styled('input')({
  width: '100%',
  paddingBottom: '2px',
  borderBottom: 'solid 1px gray'
});

const TwoWheeler = ({ caseData, userDetails }) => {
  const location = useLocation();
  const { state } = location;
//   const caseData = state?.caseData;
//   const userDetails = state?.userDetails;
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => {});

  const [formData, setFormData] = useState({
    make: '',
    model: '',
    variant: '',
    color: '',
    year: '',
    vehicleRunningCondition: '',
    chasis: '',
    fuelUsed: '',
    engineNumber: '',
    odometerType: '',
    images: [],
    video: '',
    caseStatus: '',
    insurer: '',
    inspectorName: '',
    purpose: '',
    proposer: '',
    requestor: '',
    frontTyreRhs: '',
    frontTyreLhs: '',
    rearTyreRhs: '',
    rearTyreLhs: '',
    qcName: '',
    qcStatus: '',
    remarks: '',
    inspectionDateTime: '',
    cusSign: '',
    executiveName: '',

    // Two Wheeler-specific fields
    twoWheelerHeadLight: 'Intact',
    twoWheelerVisor: 'Intact',
    twoWheelerFrontIndicatorLt: 'Intact',
    twoWheelerFrontIndicatorRt: 'Intact',
    twoWheelerFrontMudguard: 'Intact',
    twoWheelerForkHandle: 'Intact',
    twoWheelerLeftSideBody: 'Intact',
    twoWheelerShockAbsorberFrontLeft: 'Intact',
    twoWheelerShockAbsorberRearLeft: 'Intact',
    twoWheelerLegShieldLeft: 'Intact',
    twoWheelerTailLight: 'Intact',
    twoWheelerRearIndicatorLt: 'Intact',
    twoWheelerRearIndicatorRt: 'Intact',
    twoWheelerRearMudguard: 'Intact',
    twoWheelerRearViewMirrorLt: 'Intact',
    twoWheelerRearViewMirrorRt: 'Intact',
    twoWheelerRightSideBody: 'Intact',
    twoWheelerShockAbsorberRearRight: 'Intact',
    twoWheelerShockAbsorberFrontRight: 'Intact',
    twoWheelerLegShieldRight: 'Intact',
    twoWheelerSilencer: 'Intact',
    twoWheelerOdometerType: 'Intact',
    twoWheelerOdometer: 'Intact',
    twoWheelerFuelTank: 'Intact',
    twoWheelerSeat: 'Intact',
    twoWheelerLuggageBox: 'Intact',
    twoWheelerChassisLongitudeFrame: 'Intact',
    twoWheelerWheelType: 'Intact',
    twoWheelerFrontWheelRim: 'Intact',
    twoWheelerSpareWheel: 'Intact',
    twoWheelerRearWheelRim: 'Intact',
    twoWheelerRearTyre: '',
    twoWheelerFrontTyre: '',
  });

  const clearFormData = () => {
    setFormData({
      make: '',
      model: '',
      variant: '',
      color: '',
      year: '',
      vehicleRunningCondition: '',
      chasis: '',
      fuelUsed: '',
      engineNumber: '',
      odometerType: '',
      images: [],
      video: '',
      caseStatus: 'Completed',
      insurer: '',
      inspectorName: '',
      purpose: '',
      proposer: '',
      requestor: '',
      qcName: '',
      qcStatus: '',
      remarks: '',
      inspectionDateTime: '',
      cusSign: '',
      executiveName: '',

        // Two Wheeler-specific fields

        //   Front
        twoWheelerHeadLight: '',
        twoWheelerVisor: '',
        twoWheelerFrontIndicatorLt: '',
        twoWheelerFrontIndicatorRt: '',
        twoWheelerFrontMudguard: '',
        twoWheelerForkHandle: '',

        // Left Hand Side
        twoWheelerLeftSideBody: '',
        twoWheelerShockAbsorberFrontLeft: '',
        twoWheelerShockAbsorberRearLeft: '',
        twoWheelerLegShieldLeft: '',

        // Rear
        twoWheelerTailLight: '',
        twoWheelerRearIndicatorLt: '',
        twoWheelerRearIndicatorRt: '',
        twoWheelerRearMudguard: '',
        twoWheelerRearViewMirrorLt: '',
        twoWheelerRearViewMirrorRt: '',

        // Right Hand Side
        twoWheelerRightSideBody: '',
        twoWheelerShockAbsorberRearRight: '',
        twoWheelerShockAbsorberFrontRight: '',
        twoWheelerLegShieldRight: '',
        twoWheelerSilencer: '',

        // Meter
        twoWheelerOdometerType: '',
        twoWheelerOdometer: '',

        // Extra
        twoWheelerFuelTank: '',
        twoWheelerSeat: '',
        twoWheelerLuggageBox: '',

        // Chassis
        twoWheelerChassisLongitudeFrame: '',

        // Tyre & wheels
        twoWheelerWheelType: '',
        twoWheelerFrontWheelRim: '',
        twoWheelerFrontTyre: '',
        twoWheelerRearWheelRim: '',
        twoWheelerRearTyre: '',
        twoWheelerSpareWheel: '',
    });
  };

  const signzyID = 'PAYTMSZY';

  const {vehicleData, error, loading} = useVahanVehicleDetails(userDetails.licensePlate, signzyID)

  // console.log("Vehicle Data",vehicleData);

  useEffect(() => {
    if (userDetails || vehicleData) {
      setFormData(prevState => ({
        ...prevState,
        make: vehicleData?.result?.mappings?.variantIds[0]?.make || userDetails.make || '',
        model: vehicleData?.result?.model || userDetails.model || '',
        variant: vehicleData?.result?.mappings?.variantIds[0]?.variant || userDetails.variant || '',
        color: vehicleData?.result?.vehicleColour || userDetails.color || '',
        year: vehicleData?.result?.vehicleManufacturingMonthYear || userDetails.year || '',
        chasis: vehicleData?.result?.chassis || userDetails.chasis || '',
        fuelUsed: vehicleData?.result?.type || userDetails.fuelUsed || '',
        engineNumber: vehicleData?.result?.engine || userDetails.engineNumber || '',
        insuranceCompany: vehicleData?.result?.vehicleInsuranceCompanyName || userDetails.insuranceCompany || '',
        insuranceUpto: vehicleData?.result?.vehicleInsuranceUpto || userDetails.insuranceUpto || '',
        odometerType: userDetails.odometerType || '',
        images: userDetails.images || '',
        video: userDetails.video || '',
        cusSign: userDetails.cusSign || '',
        executiveName: userDetails.executiveName || '',
        insurer: userDetails.insurer || '',
        inspectorName: userDetails.inspectorName || '',
        purpose: userDetails.purpose || '',
        proposer: vehicleData?.result?.owner || userDetails.proposer || '',
        requestor: userDetails.requestor || '',
        qcName: userDetails.qcName || '',
        remarks: userDetails.remarks || '',
        twoWheelerFrontTyre: userDetails.twoWheelerFrontTyre || '',
        twoWheelerRearTyre: userDetails.twoWheelerRearTyre || '',
      }));
    }
  }, [userDetails, vehicleData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const option = [
    { value: 'Intact', label: 'Intact' },
    { value: 'Scratched', label: 'Scratched' },
    { value: 'Damaged', label: 'Damaged' },
    { value: 'Broken', label: 'Broken' },
    { value: 'Dent', label: 'Dent' },
    { value: 'NA', label: 'NA' }
  ];

  const handleFileChange = async (event, type) => {
    const documentId = caseData._id;

    const files = Array.from(event.target.files);
    try {
      // Upload each file and get the URL
      const urls = await Promise.all(files.map(async (file) => {
        // const key = `uploads/${Date.now()}_${file.name}`;
        
        const key = `images/${documentId}/${Date.now()}_${file.name}`;
        const contentType = file.type;
        const fileUrl = await uploadFile(key, file, contentType);
        return fileUrl;
      }));

      if (type === 'video') {
        // If type is 'videos', we expect a single video URL
        setFormData(prevState => ({
            ...prevState,
            [type]: urls.length > 0 ? urls[0] : ''
        }));
      } else if (type === 'cusSign') {
        setFormData(prevState => ({
          ...prevState,
          [type] : urls.length > 0 ? urls[0] : ''
        }))
      }
      else {
        setFormData(prevState => ({
          ...prevState,
          [type]: [...prevState[type], ...urls]
        }));
      }
      
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleFileRemoveImages = (index, type) => {
    setFormData(prevState => ({
      ...prevState,
      [type]: prevState[type].filter((_, i) => i !== index)
    }));
  };

  const handleFileRemoveVideo = (type) => {
    if(type === 'video') {
      URL.revokeObjectURL(formData.video);
      setFormData(prevState => ({
        ...prevState,
        video: ''
      }));
    }
  };

  const handleFileRemoveCusSign = (type) => {
      URL.revokeObjectURL(formData.cusSign);
      setFormData(prevState => ({
        ...prevState,
        [type]: ''
      }));
  };

  const handleFileDownload = (url) => {

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'; // Open in a new tab

    link.download = url.substring(url.lastIndexOf('/') + 1);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
  

  const handleSubmit = () => {

    const currentDateTime = new Date().toISOString();

    setConfirmAction(() => async () => {
      const formDataWithStatus = {
        ...formData,
        caseStatus: 'Completed',
        inspectionDateTime: formData.inspectionDateTime
      };

      try {
        setTimeout(() => {
          jwtAxios.put(`/api/case/${caseData._id}`, formDataWithStatus)
          .then(response => {
            console.log('Update successful:', response);
            clearFormData();
          })
          .catch(error => {
            console.error('Error updating data:', error);
          });
          
        }, 3000);
      }
      catch (error) {
        console.log(error);
      }
      finally {
        setOpenDialog(false);
      }

    })
      setOpenDialog(true);
    
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirm = () => {
    confirmAction();
  }
  
  if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;

  console.log("Two Wheeler CaseData Form", caseData);
  
    return (
      <Container maxWidth="md">
        <Box>
          <Box
            sx={{
              mt: 2
            }}
          >
  
            {/* Title */}
            <Box backgroundColor='#fefefe'
              sx={{
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px'
              }}
              padding={5}>
                <Typography>Name: {vehicleData?.result?.owner || userDetails.name}</Typography>
                <Typography>Email: {userDetails.email}</Typography>
                <Typography>Number: {vehicleData?.result?.mobileNumber || userDetails.number}</Typography>
                <Typography>Vehicle Name: {vehicleData?.result?.mappings?.variantIds[0]?.make || userDetails.vehicleName}</Typography>
                <Typography>Color: {vehicleData?.result?.vehicleColour || userDetails.color}</Typography>
                <Typography>License Plate: {vehicleData?.result?.regNo || userDetails.licensePlate}</Typography>
                <Typography>Address: {vehicleData?.result?.presentAddress || userDetails.address}</Typography>
              <Typography variant="h5" fontSize='24px' fontWeight='600' sx={{ mb: 3 }}>
                Two Wheeler Vehicle Detail
              </Typography>
  
              {/* Form Fields */}
              <TextField
                label="Make"
                name="make"
                value={formData.make}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Model"
                name="model"
                value={formData.model}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Variant"
                name="variant"
                value={formData.variant}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <Box display="flex" gap={3} justifyContent="space-between" mb={2}>
  
                <TextField
                  label="Color"
                  name="color"
                  value={formData.color}
                  onChange={handleChange}
                  fullWidth
                  sx={{ 
                    mb: 2,
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '& fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }
                  }}
                />
  
                <TextField
                  label="Year"
                  name="year"
                  value={formData.year}
                  onChange={handleChange}
                  fullWidth
                  sx={{ 
                    mb: 2,
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '& fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }
                  }}
                />
              </Box>
  
              <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '16px', fontWeight: '500' }}>
                  Vehicle Running Condition
                </Typography>
                <RadioGroup
                  name="vehicleRunningCondition"
                  value={formData.vehicleRunningCondition}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
  
              <TextField
                label="Chassis"
                name="chasis"
                value={formData.chasis}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Fuel Used"
                variant="outlined"
                name="fuelUsed"
                value={formData.fuelUsed}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Engine no"
                // variant="outlined"
                name="engineNumber"
                value={formData.engineNumber}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Insurance Upto"
                // variant="outlined"
                name="insuranceUpto"
                value={formData.insuranceUpto}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Insurance Company"
                // variant="outlined"
                name="insuranceCompany"
                value={formData.insuranceCompany}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Odometer"
                variant="outlined"
                name="odometerType"
                value={formData.odometerType}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Insurer/Broker"
                name="insurer"
                value={formData.insurer}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Inspector Name"
                name="inspectorName"
                value={formData.inspectorName}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Purpose"
                name="purpose"
                value={formData.purpose}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Proposer"
                name="proposer"
                value={formData.proposer}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Requestor"
                name="requestor"
                value={formData.requestor}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="QC Name"
                name="qcName"
                value={formData.qcName}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Executive Name"
                name="executiveName"
                value={formData.executiveName}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Remarks"
                name="remarks"
                value={formData.remarks}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Front
              </Typography>
  
              <SelectField
                label="Head Light"
                name="twoWheelerHeadLight"
                value={formData.twoWheelerHeadLight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Visor"
                name="twoWheelerVisor"
                value={formData.twoWheelerVisor}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Indicator LT"
                name="twoWheelerFrontIndicatorLt"
                value={formData.twoWheelerFrontIndicatorLt}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Indicator RT"
                name="twoWheelerFrontIndicatorRt"
                value={formData.twoWheelerFrontIndicatorRt}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Mudguard"
                name="twoWheelerFrontMudguard"
                value={formData.twoWheelerFrontMudguard}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Fork & Handle"
                name="twoWheelerForkHandle"
                value={formData.twoWheelerForkHandle}
                onChange={handleChange}
                options={option}
              />
  
        
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Left Hand Side
              </Typography>
  
              <SelectField
                label="Left Side Body"
                name="twoWheelerLeftSideBody"
                value={formData.twoWheelerLeftSideBody}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Shock Absorber Front Left"
                name="twoWheelerShockAbsorberFrontLeft"
                value={formData.twoWheelerShockAbsorberFrontLeft}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Shock Absorber Rear Left"
                name="twoWheelerShockAbsorberRearLeft"
                value={formData.twoWheelerShockAbsorberRearLeft}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Leg Shield Left"
                name="twoWheelerLegShieldLeft"
                value={formData.twoWheelerLegShieldLeft}
                onChange={handleChange}
                options={option}
              />
  
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Rear
              </Typography>
  
              <SelectField
                label="Tail Light"
                name="twoWheelerTailLight"
                value={formData.twoWheelerTailLight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Indicator LT"
                name="twoWheelerRearIndicatorLt"
                value={formData.twoWheelerRearIndicatorLt}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Indicator RT"
                name="twoWheelerRearIndicatorRt"
                value={formData.twoWheelerRearIndicatorRt}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Mudguard"
                name="twoWheelerRearMudguard"
                value={formData.twoWheelerRearMudguard}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear View Mirror LT"
                name="twoWheelerRearViewMirrorLt"
                value={formData.twoWheelerRearViewMirrorLt}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear View Mirror RT"
                name="twoWheelerRearViewMirrorRt"
                value={formData.twoWheelerRearViewMirrorRt}
                onChange={handleChange}
                options={option}
              />
              
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Right Hand Side
              </Typography>
  
              <SelectField
                label="Right Side Body"
                name="twoWheelerRightSideBody"
                value={formData.twoWheelerRightSideBody}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Shock Absorber Rear Right"
                name="twoWheelerShockAbsorberRearRight"
                value={formData.twoWheelerShockAbsorberRearRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Shock Absorber Front Right"
                name="twoWheelerShockAbsorberFrontRight"
                value={formData.twoWheelerShockAbsorberFrontRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Leg Shield Right"
                name="twoWheelerLegShieldRight"
                value={formData.twoWheelerLegShieldRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Silencer"
                name="twoWheelerSilencer"
                value={formData.twoWheelerSilencer}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Meter
              </Typography>
  
              <SelectField
                label="Odometer Type"
                name="twoWheelerOdometerType"
                value={formData.twoWheelerOdometerType}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Odometer"
                name="twoWheelerOdometer"
                value={formData.twoWheelerOdometer}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Chassis
              </Typography>
  
              <SelectField
                label="Chassis Longtitude Frame"
                name="twoWheelerChassisLongitudeFrame"
                value={formData.twoWheelerChassisLongitudeFrame}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Tyre & Wheels
              </Typography>
  
  
              <SelectField
                label="Wheel Type"
                name="twoWheelerWheelType"
                value={formData.twoWheelerWheelType}
                onChange={handleChange}
                options={option}
              />
  
              {/* <TextField
                label="Front Tyre Right"
                name="frontTyreRhs"
                value={formData.frontTyreRhs}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              /> */}
  
              <SelectField
                label="Front Wheel Rim"
                name="twoWheelerFrontWheelRim"
                value={formData.twoWheelerFrontWheelRim}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Wheel Rim"
                name="twoWheelerRearWheelRim"
                value={formData.twoWheelerRearWheelRim}
                onChange={handleChange}
                options={option}
              />
  
              {/* <TextField
                label="Rear Tyre Right"
                name="rearTyreRhs"
                value={formData.rearTyreRhs}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              /> */}
  
              {/* <SelectField
                label="Rear Tyre Left"
                name="rearTyreLhs"
                value={formData.rearTyreLhs}
                onChange={handleChange}
                options={option}
              /> */}

              <SelectField
                label="Spear Wheel"
                name="twoWheelerSpareWheel"
                value={formData.twoWheelerSpareWheel}
                onChange={handleChange}
                options={option}
              />

              <TextField
                label="Front Tyre"
                name="twoWheelerFrontTyre"
                value={formData.twoWheelerFrontTyre}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Rear Tyre"
                name="twoWheelerRearTyre"
                value={formData.twoWheelerRearTyre}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              {/* /////////////////////////////////////////////////////////////// */}
   
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                Extra
              </Typography>
  
              <SelectField
                label="Fuel Tank"
                name="twoWheelerFuelTank"
                value={formData.twoWheelerFuelTank}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Seat"
                name="twoWheelerSeat"
                value={formData.twoWheelerSeat}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Luggage Box"
                name="twoWheelerLuggageBox"
                value={formData.twoWheelerLuggageBox}
                onChange={handleChange}
                options={option}
              />
  
              <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '16px', fontWeight: '500' }}>
                  QC Status
                </Typography>
                <RadioGroup
                  name="qcStatus"
                  value={formData.qcStatus}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="Recommended" control={<Radio />} label="Recommended" />
                  <FormControlLabel value="Not Recommended" control={<Radio />} label="Not Recommended" />
                </RadioGroup>
              </FormControl>
  
              <Typography variant="subtitle1" marginTop={3} marginBottom={3} gutterBottom sx={{fontSize: '18px', fontWeight: '500' }}>Customer Sign</Typography>
              <CustomInput
                type="file"
                accept="image/png, image/gif, image/jpeg"
                // multiple
                onChange={(event) => handleFileChange(event, 'cusSign')}
                inputProps={{ accept: 'image/*' }}
                // fullWidth
                // margin="normal"
              />
  
              <Grid container spacing={2} mt={3}>
                {formData.cusSign.length > 0 ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <img src={formData.cusSign} alt="logo"/>
                    <Button variant="outlined" onClick={() => handleFileRemoveCusSign('cusSign')}>
                      Remove
                    </Button>
                  </Grid>
                ) : null
                  
                }
              </Grid>
  
              <Typography variant="subtitle1" marginTop={3} marginBottom={3} gutterBottom sx={{fontSize: '18px', fontWeight: '500' }}>Upload Images</Typography>
              <CustomInput
                type="file"
                accept="image/png, image/gif, image/jpeg"
                multiple
                onChange={(event) => handleFileChange(event, 'images')}
                inputProps={{ accept: 'image/*' }}
                // fullWidth
                // margin="normal"
              />
              <Grid container spacing={2} mt={3}>
                {formData.images.map((image, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    {/* <Typography variant="body2">{image}</Typography> */}
                    <img src={image} alt="logo"/>
                    <Grid display='flex' justifyContent='space-between'>
                      <Button variant="outlined" onClick={() => handleFileRemoveImages(index, 'images')}>
                        Remove
                      </Button>
  
                      <Button variant="outlined" onClick={() => handleFileDownload(image)}>
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
  
              <Typography variant="subtitle1" marginTop={3} marginBottom={3} gutterBottom sx={{fontSize: '18px', fontWeight: '500' }}>Upload Videos</Typography>
              <Input
                type="file"
                accept='video/mp4, video/mpeg'
                multiple
                onChange={(event) => handleFileChange(event, 'video')}
                inputProps={{ accept: 'video/*' }}
                fullWidth
                margin="normal"
              />
  
              <Grid container spacing={2} m={3}>
                {formData.video.length > 0 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* <Typography variant="body2">{formData.video}</Typography> */}
                      <video controls style={{ width: '100%' }}>
                        <source src={formData.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Grid>
  
                    <Grid display='flex' justifyContent='space-between' gap={5}>
                      <Button variant="outlined" onClick={() => handleFileRemoveVideo('video')}>
                        Remove
                      </Button>
  
                      <Button variant="outlined" onClick={() => handleFileDownload(formData.video)}>
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                ) : <Typography variant="body2">Wait Till Upload ....</Typography>}
              </Grid>
  
              <Grid container spacing={2} m={3}>
                <TextField
                  label="Inspection Date & Time"
                  name="inspectionDateTime"
                  value={formData.inspectionDateTime}
                  onChange={handleChange}
                  type='datetime-local'
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ 
                    mb: 2,
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '& fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }
                  }}
                />
              </Grid>
  
            </Box>
          </Box>
        </Box>
  
  
  
        {/* Footer */}
        <Box sx={{
          alignItems: 'center',
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        >
          <Box display="flex" padding={2} backgroundColor='#fefefe' justifyContent="flex-end" alignItems="center"
            sx={{
              borderBottomRightRadius: '20px',
              borderBottomLeftRadius: '20px'
            }}
          >
            <Button
              sx={{
                backgroundColor: '#E5D3FC',
                color: '#6B08EC',
                fontWeight: '600',
                fontSize: '16px',
                borderRadius: '30px',
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
  
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
              {"Confirm Submission"}
          </DialogTitle>
          <DialogContent>
              <p>Are you sure you want to confirm?</p>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                  Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                  Confirm
              </Button>
          </DialogActions>
        </Dialog>
  
      </Container>
    );
};

export default TwoWheeler;
