import React from 'react';
import { connect } from 'react-redux';
// import Snackbar from '@material-ui/core/Snackbar';
import {closeSnackbar} from "../toolkit/actions/snackbar";
import Alert from "@mui/material/Alert";

// eslint-disable-next-line react/prop-types
const CustomSnackbar = ({ open, message, type, handleClose }) => {
    console.log("init", open, message, handleClose)
    return (<></>
        // <Snackbar
        //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
        //     open={open}
        //     severity={type}
        //     autoHideDuration={5000}
        //     onClose={handleClose}
        // >
        //     <Alert
        //         onClose={handleClose}
        //         severity={type}
        //         variant="filled"
        //         sx={{ width: '100%' }}
        //     >
        //         {message}
        //     </Alert>
        // </Snackbar>
    );
};

const mapStateToProps = (state) => ({
    open: state.snackbar.open,
    message: state.snackbar.message,
    type: state.snackbar.type
});

const mapDispatchToProps = (dispatch) => ({
    handleClose: () => dispatch(closeSnackbar())
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSnackbar);
