import { useState, useEffect } from 'react';
// import axios from 'axios';
import jwtAxios from '@crema/services/auth/jwt-auth';

const useVahanVehicleDetails = (vehicleNumber, signzyID) => {
  const [vehicleData, setVehicleData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      setLoading(true);
      try {
        const response = await jwtAxios.post('api/vehicledetails', {
          vehicleNumber,
          signzyID
        });

        setVehicleData(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleDetails();
  }, [vehicleNumber, signzyID]);

  return { vehicleData, error, loading };
};

export default useVahanVehicleDetails;
