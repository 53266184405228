import React, {useEffect, useMemo, useState} from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { Button, Hidden } from '@mui/material';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import OrderTable from '../PendingOrders/OrderTable';
import { useAppDispatch, useAppSelector } from '../../../toolkit/hooks';
import { getRecentOrders } from '../../../toolkit/actions';
import jwtAxios from "../../../@crema/services/auth/jwt-auth";
import {useNavigate} from "react-router-dom";
import DateFormat from "../../../@crema/helpers/DateFormat";
import dayjs from 'dayjs';

const CompletedOrders = () => {
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const [orders, setRecentOrders] = useState([])
  const [page, setPage] = useState(0);
  const [search, setSearchQuery] = useState('');
  const [totalOrders, setTotalOrders] = useState(0)
  const recentOrders = useMemo(() => {
    if (orders.length > 0) {
      console.log("wut?")
            return orders
          .map(cats => ({ id: cats._id, orderID: cats.orderID, creditsUsed: cats.creditsUsed, companyName: cats.companyName, credentialID: cats.credentialID, "product": cats.orderDetails.map((orderrr) => {return `${orderrr.name} - ${orderrr.sku} - ${orderrr.qty}\n`}), "date": dayjs(cats.createdOn).format('MM/DD/YYYY HH:mm:ss'), "status": cats.status, "price": cats.ammount, "notes": cats.notes }));
    } else {
      return []
    }
  }, [orders, search])

  const onPageChange = (event: any, value: number) => {
    setPage(value);
  };
  const updateTable = () => {
    const url = `orders?status=Shipped&page=${page+1}`
    jwtAxios.get( url ).then((response) => {
        setRecentOrders(response.data.orders);
        setTotalOrders(response.data.totalOrders)
      // setLoadingTable(false)
      console.log("loading the pending order list", response.data)
    });
  }
  useEffect(() => {
    updateTable()
  }, [page]);

  const onSearchOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setPage(0);
  };

  const locate = useNavigate()
  const handleViewOrder = (id) => {
    console.log("the id", id)
    locate("/orders/viewOrder", {state: {id}})
  }
  return (
    <>
      <AppsContainer
        title={messages['eCommerce.completedOrders'] as string}
        fullView
      >
        <AppsHeader>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            width={1}
            justifyContent='space-between'
          >
            <AppSearchBar
              iconPosition='right'
              overlap={false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSearchOrder(event)
              }
              placeholder={messages['common.searchHere'] as string}
            />
            <Box display='flex' flexDirection='row' alignItems='center'>
              {/*<Button variant='contained' color='primary'>*/}
              {/*  Add Order*/}
              {/*</Button>*/}

              <Hidden smDown>
                <AppsPagination
                  rowsPerPage={10}
                  count={totalOrders}
                  page={page}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </Box>
          </Box>
        </AppsHeader>

        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <OrderTable orderData={recentOrders} handleViewOrder={handleViewOrder} />
        </AppsContent>

        <Hidden smUp>
          <AppsPagination
            rowsPerPage={10}
            count={totalOrders}
            page={page}
            onPageChange={onPageChange}
          />
        </Hidden>
      </AppsContainer>
      <AppInfoView />
    </>
  );
};

export default CompletedOrders;
