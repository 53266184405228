import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

const SelectField = ({ label, value, onChange, options, name }) => {
  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        sx={{
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          '& fieldset': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: '0px'
          }
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
