import axios from 'axios';

// export const API_BASE_URL = 'https://locationtec.justthinktech.in/locationtec/v1'
export const API_BASE_URL = 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/'
export const API_KEY = 'c747b51cc1b18e6e9f4fdd72d6ada6c8'
export const S3_BUCKET_NAME = ''
export const S3_REGION = ''
export const S3_ACCESS_KEY = ''
export const S3_SECRET_KEY = ''

export async function renewAccessToken() {

    var data = JSON.stringify({
        "refreshToken": getCookie('rt')
    });

    var config = {
        method: 'post',
        url: API_BASE_URL + '/renewtoken',
        headers: { 
            'apikey': API_KEY, 
            'Content-Type': 'application/json'
        },
        data : data
    };

    const response = await axios(config);
    return response
}

export function sessionExpired() {
    alert("Session Expired. Please login again")
    localStorage.clear()
    sessionStorage.clear()
    removeAllCookies()
    window.location = "/login";
    // window.location.reload();   
}

export function handleRenewAccessTokenError(error) {
    console.log(error.response.data)
    if(error.response.data.message === "REFRESH_TOKEN_EXPIRED") {
        sessionExpired()
    } else {
        alert("Server Down. Please try after sometime")
        console.log(error)
    }
}

export async function setCookie(name, value) {
    document.cookie = name + "="+ value + "; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    return true
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function removeAllCookies() {
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            var p = window.location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            };
            d.shift();
        }
    }
};

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function getDefaultDate() {
    const today = new Date();
    const date = today.setDate(today.getDate()); 
    const defaultValue = new Date(date).toISOString().split('T')[0]

    return defaultValue
}