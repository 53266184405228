import React, { useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import AppTableContainer from '@crema/components/AppTableContainer';
import AppLoader from '@crema/components/AppLoader';
import { useLocation, useParams } from "react-router-dom";

type Props = {
  tabledata: any[];
  loading: boolean;
  edit_fun: any;
  edit_name?: string;
  delete_fun: any;
  delete_name?: string;
  phasee?: string;
  view_name: string;
  showAction?: boolean
};

const ListingTable = ({ tabledata, loading, edit_fun, delete_fun, edit_name = "Edit User", delete_name = "Delete User", phasee = "something", view_name="View User", showAction = true }: Props) => {
  let { phase } = useParams<{ phase?: string }>() || { phase: phasee };
  if (!phase) {
    phase = phasee;
  }
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const report = !!searchParams.get('report');
  const keys = useMemo(() => {
    if (tabledata.length > 0) {
      const ke = Object.keys(tabledata[0]);
      if ( showAction) {
        ke.push("Actions");
      }
      // ke.push("Actions");
      return ke;
    }
  }, [tabledata]);

  return (
    <AppTableContainer>
      {loading ? (
        <AppLoader />
      ) : (
        <Table stickyHeader className='table'>
          <TableHead>
            <TableHeading keys={keys} />
          </TableHead>
          <TableBody>
            {tabledata.map((data) => (
              <TableItem
                key={data._id}
                data={data}
                keys={keys}
                edit_fun={edit_fun}
                delete_fun={delete_fun}
                edit_name={edit_name}
                delete_name={delete_name}
                view_name={view_name}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </AppTableContainer>
  );
};

export default ListingTable;
