import React, {useEffect, useMemo, useState} from 'react';
import AppGridContainer from '@crema/components/AppGridContainer';
import { Fonts } from '@crema/constants/AppEnums';
import {Box, Button, FormControlLabel, Grid, Hidden, Slide, Switch, TextField} from '@mui/material';
import AppScrollbar from '@crema/components/AppScrollbar';
import AppCard from '@crema/components/AppCard';
import jwtAxios, {setAuthToken} from "../../../@crema/services/auth/jwt-auth";
import {openSnackbar} from "../../../toolkit/actions/snackbar";
import {useDispatch} from "react-redux";
import uploadFile from "../../../s3/s3.upload";
import AppsHeader from "../../../@crema/components/AppsContainer/AppsHeader";
import AppSearchBar from "../../../@crema/components/AppSearchBar";
import AppsPagination from "../../../@crema/components/AppsPagination";
import AppsContent from "../../../@crema/components/AppsContainer/AppsContent";
import ListingTable from "./Listing/ListingTable";
import IntlMessages from "../../../@crema/helpers/IntlMessages";
import AppConfirmDialog from "../../../@crema/components/AppConfirmDialog";

function Brands() {
    const [formData, setFormData] = useState({
        brandName: '',
        image: '',
        status: '',
        featured: false
    });
    const [brands, setBrands] = useState([])
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [brandToDelete, setBrandToDelete] = useState(null);
    const brandsList = useMemo(() => {
        return brands
            .map(brand => ({  ID: brand._id, Name:brand.brandName, Image: brand.image, }));
    }, [brands, search]);
    const [errors, setErrors] = useState({
        brandName: '',
        image: '',
        status: ''
    });
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(name, value)
        const newValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: newValue
        });

        !value.trim() ? setErrors({ ...errors, [name]: name + " should not be empty" }) : setErrors({ ...errors, [name]: "" });
    };
    const handleImageChange = (e) => {

        const file = e.target.files[0];
        console.log("what to say?", file)
        if (file && file.type.startsWith('image/')) {
            setFormData({ ...formData, image: file });
            setErrors({ ...errors, "image": '' });
        } else {
            e.target.value = null;
            dispatch(openSnackbar('Only images allowed', 'error'));
        }
    };
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Perform form submission
            const fileExtension = formData.image.name.split('.').pop();
            const sendData = formData
            try{
                const imageUrl = await uploadFile("brands/"+formData.brandName+Date.now()+"."+fileExtension, formData.image); // Upload the image using uploadFile function
                sendData.image = imageUrl
                console.log(formData);
            } catch (e) {
                dispatch(openSnackbar("Some error occured please retry", 'error'));
                return
            }
            try {

                const response = await jwtAxios.post('brand', sendData);
                dispatch(openSnackbar('Brand added successfully', 'success'));
                console.log('Form submitted:', sendData);


            } catch (e) {
                dispatch(openSnackbar('Error adding brand', 'error'));
            }

            const fileInput = document.getElementById('image'); // Get the file input element by ID
            if (fileInput) {
                fileInput.value = ''; // Set the value of the file input to an empty string to clear it
            }
            // Reset form after submission
            setFormData({
                brandName: '',
                image: '',
                status: ''
            });
            // Clear errors
            setErrors({
                brandName: '',
                image: '',
                status: ''
            });
        } else {
            console.log('Form validation failed');
        }
    };

    const updateTable = () => {
        jwtAxios.get( 'brand' ).then((response) => {
            setBrands(response.data);
            console.log("loading the brands list", response.data)
        });
    }

    useEffect(() => {
        updateTable()
    }, []);

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...errors };

        // Validation for brandName
        if (!formData.brandName.trim()) {
            newErrors.brandName = 'Brand name is required';
            valid = false;
        } else {
            newErrors.brandName = '';
        }


        // Validation for status
        if (!formData.status.trim()) {
            newErrors.status = 'Status is required';
            valid = false;
        } else {
            newErrors.status = '';
        }

        if (!formData.image) {
            newErrors.image = 'Image is required';
        }

        // Update errors state
        setErrors(newErrors);

        return valid;
    };

    const delete_fun = async(data) => {
        console.log(data,"Product delete data")
        setBrandToDelete(data);
        setDeleteDialogOpen(true);
        // try {
        //     await jwtAxios.delete(`product/${data.asin}`);
        //     updateTable();
        //     dispatch(openSnackbar('Product deleted successfully', 'success'));
        //   } catch (error) {
        //     dispatch(openSnackbar(error.response.data.message, 'error'));
        //     console.log(error, "error deleting product");
        //   }
    }
    const handleConfirmDelete = async () => {
        if (brandToDelete) {
            try {
                await jwtAxios.delete(`brand/${brandToDelete.ID}`);
                updateTable();
                dispatch(openSnackbar('Product deleted successfully', 'success'));
            } catch (error) {
                dispatch(openSnackbar(error.response.data.message, 'error'));
                console.log(error, "error deleting blog");
            } finally {
                setDeleteDialogOpen(false);
                setBrandToDelete(null);
            }
        }
    };
    return (
        <>
            <Box
                component='h2'
                sx={{
                    fontSize: 16,
                    color: 'text.primary',
                    fontWeight: Fonts.SEMI_BOLD,
                    mb: {
                        xs: 2,
                        lg: 4,
                    },
                }}
            >
                {'Brands'}
            </Box>
            <Slide direction='right' in mountOnEnter unmountOnExit>
                <Grid item xs={12} lg={8}>
                    <AppScrollbar style={{
                        marginBottom: "2vh"
                    }}>
                        <AppCard>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    className='mt-8 mb-16 mx-6'
                                    label='Name'
                                    autoFocus
                                    id='name'
                                    name='brandName'
                                    variant='outlined'
                                    fullWidth
                                    value={formData.brandName}
                                    onChange={handleChange}
                                    error={!!errors.brandName}
                                    helperText={errors.brandName}
                                />

                                <br /><br />

                                <TextField
                                    className='mt-8 mb-16 mx-6'
                                    label='Image'
                                    type='file'
                                    id='image'
                                    name='image'
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    onChange={handleImageChange}
                                    error={!!errors.image}
                                    helperText={errors.image}
                                />

                                <br /><br />

                                <TextField
                                    className='mt-8 mb-16 mx-6'
                                    label='Status'
                                    id='status'
                                    name='status'
                                    variant='outlined'
                                    fullWidth
                                    value={formData.status}
                                    onChange={handleChange}
                                    error={!!errors.status}
                                    helperText={errors.status}
                                />

                                <br />
                                <br />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData.featured}
                                            onChange={handleChange}
                                            name='featured'
                                        />
                                    }
                                    label='Feature it on homepage?'
                                />

                                <br />
                                <br />


                                <Button
                                    sx={{
                                        display: 'block',
                                        minWidth: 100,
                                    }}
                                    color='primary'
                                    variant='contained'
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </form>
                        </AppCard>
                    </AppScrollbar>
                </Grid>
            </Slide>
            <Slide direction='right' in mountOnEnter unmountOnExit>
                <Grid item xs={12} lg={9}>
                    <AppCard
                        title={
                            <AppsHeader>
                                <Box
                                    display='flex'
                                    flexDirection='row'
                                    alignItems='center'
                                    width={1}
                                    justifyContent='space-between'
                                >
                                    <AppSearchBar
                                        iconPosition='right'
                                        overlap={false}
                                        onChange={(event) =>
                                            setSearch(event.target.value)
                                        }
                                        placeholder={"Search Here"}
                                    />
                                    <Box
                                        display='flex'
                                        flexDirection='row'
                                        alignItems='center'
                                        justifyContent='flex-end'
                                    >
                                        <Hidden smDown>
                                            <AppsPagination
                                                rowsPerPage={10}
                                                count={10}
                                                page={page}
                                            />
                                        </Hidden>
                                    </Box>
                                </Box>
                            </AppsHeader>
                        }
                        headerStyle={{ p: 0 }}
                        contentStyle={{ p: 0 }}
                    >
                        <AppsContent
                            sx={{
                                paddingTop: 2.5,
                                paddingBottom: 2.5,
                            }}
                        >
                            <ListingTable delete_fun={delete_fun} tabledata={brandsList} view_name={""} delete_name={"Delete Brand Logo"} edit_name={""} loading={brands.length <= 0 ? true : false}  />
                        </AppsContent>
                        <Hidden smUp>
                            <AppsPagination
                                rowsPerPage={10}
                                count={10}
                                page={page}
                            />
                        </Hidden>
                    </AppCard>
                </Grid>
            </Slide>
            <AppConfirmDialog
                open={isDeleteDialogOpen}
                onDeny={() => setDeleteDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                title={"Are you sure that you want to delete this Brand Logo?"}
                dialogTitle={"Delete Brand Logo"}
            />
        </>
    );
}

export default Brands;
