import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import AppDialog from '@crema/components/AppDialog';
import AppGridContainer from '@crema/components/AppGridContainer';
import Grid from '@mui/material/Grid';
import { DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import ContactActions from './ContactActions';
import OtherDetails from './OtherDetails';
import PersonalDetails from './PersonalDetails';
import { ContactObjType } from '@crema/types/models/apps/Contact';
import { useAppDispatch } from '../../../../toolkit/hooks';
import { onUpdateSelectedContact } from '../../../../toolkit/actions';
import {useLocation, useParams} from "react-router-dom";

type Props = {
  isShowDetail: boolean;
  selectedContact: any;
  onShowDetail: (show: boolean) => void;
  onSelectContactsForDelete?: (ids: number[]) => void;
  onOpenEditContact?: (contact: ContactObjType) => void;
  edit_fun?: any, edit_name?: string,
  delete_fun?: any, delete_name?: string
};

const ContactDetail = (props: Props) => {
  const {
    isShowDetail,
    selectedContact,
    onShowDetail,
    onSelectContactsForDelete,
    onOpenEditContact,
    edit_fun, delete_fun, edit_name = "Edit", delete_name= "Delete"
  } = props;
  const { phase } = useParams<{ phase?: string }>() || {};
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const report = !!searchParams.get('report');
  const [contact, setContact] = useState<any>(selectedContact);

  useEffect(() => {
    setContact(selectedContact);
  }, [selectedContact]);

  const onChangeStarred = (checked: boolean) => {
    const updatedContact = contact;
    contact.isStarred = checked;
    setContact(updatedContact);
    dispatch(onUpdateSelectedContact(contact as ContactObjType));
  };

  const onDeleteContact = () => {
    onSelectContactsForDelete([contact.id]);
    onShowDetail(false);
  };

  return (
    <AppDialog
      sxStyle={{
        '& .MuiPaper-root:hover': {
          '& .btn-action-view': {
            opacity: 1,
            visibility: 'visible',
          },
        },
      }}
      onClose={() => onShowDetail(false)}
      hideClose
      open={isShowDetail}
      title={
        "Credetaials ID - " + contact._id
      }
    >
      {contact ? (
        <div>
          <Box
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              ml: -6,
              mr: -6,
              pl: 5,
              pr: 5,
              pb: 4,
            }}
          >
            <Box
              sx={{
                mb: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {contact.image ? (
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    mb: 2.5,
                  }}
                  src={contact.image}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    mb: 2.5,
                  }}
                >
                  {contact?.firstName?.[0]?.toUpperCase()}
                </Avatar>
              )}
              <Box component='h3'>{contact.firstName} {contact.lastName}</Box>
            </Box>
          </Box>

          <Box
            sx={{
              pt: 5,
            }}
          >
            <AppGridContainer>
              <Grid item xs={12} md={6}>
                <PersonalDetails contact={contact} />
              </Grid>

              <Grid item xs={12} md={6}>
                <OtherDetails contact={contact} />
              </Grid>
            </AppGridContainer>
          </Box>
        </div>
      ) : (
        <div />
      )}
      <DialogActions>
        {!report && phase==="review" && (
            <>
            <Button
                variant='outlined'
                color='primary'
                type='submit'
                sx={{ width: 100 }}
                onClick={() => edit_fun(contact)}
            >
              {edit_name}
            </Button>
            <Button
              variant='outlined'
              color='error'
              type='submit'
              sx={{ width: 100 }}
              onClick={() => delete_fun(contact)}
            >
              {delete_name}
            </Button></>
        )}

        <Button
          variant='outlined'
          color='error'
          type='submit'
          sx={{ width: 100 }}
          onClick={() => onShowDetail(false)}
        >
          Close
        </Button>
      </DialogActions>
    </AppDialog>
  );
};

export default ContactDetail;
