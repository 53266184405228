import axios from '@crema/services/axios';
import type { AxiosResponse } from 'axios';

const jwtAxios = axios.create({
  baseURL: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/',
  // baseURL: 'http://localhost:8080/smartinspectionai/v1/',
  headers: {
    'Content-Type': 'application/json',
    'apikey': "c747b51cc1b18e6e9f4fdd72d6ada6c8"
  },
});

// Request Interceptor to Add the Authorization Token
jwtAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Get the token from local storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Add token to the Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor for Error Handling
jwtAxios.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (err: any) => {
    if (err.response && err.response.data.message === 'Invalid Credentials') {
      // Handle invalid credentials error
      console.log('Need to logout user');
      localStorage.removeItem('userDetails');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token?: string) => {
  if (token) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
