import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import jwtAxios from "../../../../@crema/services/auth/jwt-auth";
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import {openSnackbar} from "../../../../toolkit/actions/snackbar";

const StyledTableCell = styled(TableCell)(() => ({
    fontSize: 14,
    padding: 8,
    width: "150px",
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    '&:first-of-type': {
        paddingLeft: 20,
    },
    '&:last-of-type': {
        paddingRight: 20,
    },
}));

type TableItemProps = {
    data: any;
    handleViewOrder: any;
};

const TableItem: React.FC<TableItemProps> = ({ data, handleViewOrder }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [notes, setNotes] = useState(data.notes || "null");
    const [originalNotes, setOriginalNotes] = useState(data.notes || "null");
    const [qtyArrived, setQtyArrived] = useState(data.qtyArrived || 0);
    const dispatch = useDispatch(); // Getting dispatch function

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        jwtAxios.put('orderNotes/' + data.orderID, { notes })
            .then((response) => {
                console.log("Order notes updated", response.data);
                dispatch(openSnackbar('Notes updated successfully', 'success'));
            })
            .catch((error) => {
                dispatch(openSnackbar(error.response.data.message, 'error'));
            });
        setOriginalNotes(notes);
        setIsEditing(false);
    };

    const handleResetClick = () => {
        setNotes(originalNotes || "work in progress");
        setIsEditing(false);
    };

    const saveQtyArrived = _.debounce((updatedQty) => {
        jwtAxios.put(`orderQtyArrived/${data.orderID}`, { qtyArrived: updatedQty })
            .then((response) => {
                console.log("Qty Arrived updated", response.data);
                dispatch(openSnackbar('Quantity arrived updated successfully', 'success'));
            })
            .catch((error) => {
                dispatch(openSnackbar(error.response.data.message, 'error'));
            });
    }, 3000);

    const handleQtyArrivedChange = (e) => {
        const updatedQty = e.target.value;
        setQtyArrived(updatedQty);
        saveQtyArrived(updatedQty);
    };

    const getPaymentStatusColor = () => {
        switch (data.status) {
            case 'Pending':
                return '#F84E4E';
            case 'Delivered':
                return '#43C888';
            default:
                return '#E2A72E';
        }
    };

    return (
        <TableRow key={data.id} className='item-hover'>
            <StyledTableCell component='th' scope='row'>
                {data.orderID}
            </StyledTableCell>
            <StyledTableCell align='left'>{data.date}</StyledTableCell>
            <StyledTableCell align='left'><pre>{data.product}</pre></StyledTableCell>
            <StyledTableCell align='left'>
                <TextField
                    value={qtyArrived}
                    onChange={handleQtyArrivedChange}
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                />
            </StyledTableCell>
            <StyledTableCell align='left'>{data.companyName}</StyledTableCell>
            <StyledTableCell align='left'>{data.credentialID}</StyledTableCell>
            <StyledTableCell align='left'>{data.price}</StyledTableCell>
            <StyledTableCell align='left'>{data.creditsUsed}</StyledTableCell>
            <StyledTableCell align='left'>
                {isEditing ? (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <TextField
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                        <div style={{display: "flex", flexDirection: "row"}}>
                        <Button onClick={handleSaveClick} color="primary">
                            Save
                        </Button>
                        <Button onClick={handleResetClick} color="secondary">
                            Reset
                        </Button>
                        </div>
                    </div>
                ) : (
                    <span onClick={handleEditClick}>
            {notes}
          </span>
                )}
            </StyledTableCell>
            <TableCell align='right'>
                <Button style={{ fontSize: 14 }} onClick={() => handleViewOrder(data.id)}>
                    View Order
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default TableItem;
