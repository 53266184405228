import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  pdf,
  Link,
} from "@react-pdf/renderer";
// import LogoImg from "./images/Logo.jpeg";
// import SmartInspectLogo from "./images/SmartInspectLogo.jpeg";
import SmartInspectLogo from '../../../../../../src/@crema/components/PdfDownload/images/SmartInspectLogo.jpeg'
import AdsumLogo from "../../../../../../src/@crema/components/PdfDownload/images/AdsumLogo.jpeg";
import CarImg from "../../../../../../src/@crema/components/PdfDownload/images/CarImg.jpg";
import Sign from "../../../../../../src/@crema/components/PdfDownload/images/Sign.png";
import Stamp2 from "../../../../../../src/@crema/components/PdfDownload/images/Stamp3.jpg";
// import Barcode from "./images/BarCode.png";
import PlayBtn from "../../../../../../src/@crema/components/PdfDownload/images/Play.png";
import { formatIsoDateTime } from "@crema/components/PdfDownload/dateAndTimeFormat";
// import ImageGallery from "./imagesGallery";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    padding: "0",
  },
  page1: {
    width: "100%",
    height: "100vh",
    padding: "0",
  },
  Header: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#7000e4",
    width: "100%",
    padding: "10px 10px",
    position: "relative",
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    justifyContent: "space-between",
  },
  logo: {
    width: 50,
    height: 50,
    marginRight: "16px",
    borderRadius: "5px",
  },
  brandDetails: {
    marginLeft: "2px",
    width: "40%",
  },
  brandTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: "#ffffff",
    marginBottom: "4px",
  },
  description: {
    fontSize: 9,
    color: "#e0e0e0",
    padding: "1px 0px",
  },
  descriptionss: {
    fontSize: 10,
    color: "#e0e0e0",
    padding: "1px 0px",
    fontWeight: 800,
  },
  boldText1: {
    fontWeight: 800,
    fontSize: 10,
    color: "#ffffff",
  },
  contactInfo: {
    flexDirection: "column",
    marginLeft: "16px",
    width: "40%",
  },
  contactDetail: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5px",
  },
  contactTitle: {
    width: "100px",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: 10,
  },
  logImg: {
    height: "20px",
    width: "85px",
    borderRadius: 20, 
    overflow: 'hidden',
  },
  section2: {
    backgroundColor: "#ede4f6",
    width: "100%",
    height: "10%",
    // padding: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
  },
  MainFlexBox: {
    width: "100%",
  },
  flexMainBox: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    paddingLeft: "10px",
  },
  MainCarImg: {
    height: "100%",
    width: "200px",
    objectFit: "cover",
  },
  descriptions: {
    width: "25%",
    padding: "5px",
    fontSize: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  section3: {
    backgroundColor: "white",
    width: "100%",
    // height: "15%",
    display: "flex",
    flexDirection: "row",
  },

  largeBox: {
    width: "100%",
    padding: "2px 5px",
    backgroundColor: "white",
  },
  mainBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "5px",
    padding: "1px",
    fontSize: 7,
    color: "black",
    textAlign: "center",
  },
  subBlock: {
    width: "30%",
    textAlign: "left",
    marginRight: '5px'
  },
  mainBlocks: {
    marginBottom: "5px",
    fontSize: 12,
    color: "black",
  },
  mainBlocks1: {
    marginBottom: "5px",
    fontSize: 15,
    fontWeight: "bold",
    color: "black",
  },
  mainBlocks2: {
    marginBottom: "5px",
    fontSize: 10,
    color: "black",
  },
  CenteText: {
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
    alignItems: "flex-end",
    fontSize: 11,
    // marginBottom: "10px",
    padding: "2px",
    color: "gray",
  },
  letterStyle: {
    fontStyle: "italic",
    fontSize: 12,
    color: 'black'
  },
  BoldTitles: {
    fontSize: 14,
    color: "black",
    marginRight: "10px",
  },
  section4: {
    backgroundColor: "#ffffff",
    width: "100%",
    // height: "55%",
    padding: "0px 10px",
    flexDirection: "row",
    // justifyContent: "space-between",
    gap: "10px",
    alignItems: "flex-start",
  },
  carDetailsBox: {
    width: "32%",
    // height: "100%",
    borderRadius: "5px",
    overflow: "hidden",
    backgroundColor: "white",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  caseInfo: {
    width: "100%",
    // height: "30%",
    backgroundColor: "white",
    borderRadius: "10px",
    // marginBottom: "25px",
  },
  caseBox: {
    padding: "0 10px",
    borderRadius: "10px",
    border: "1px solid lightgray",
  },
  mainCarImage: {
    width: "100%",
    // height: "50%",
    objectFit: "contain",
    padding: "10px",
    border: "1px solid gray",
    borderRadius: "10px",
  },
  mainBox: {
    width: "32%",
    color: "black",
    fontSize: 10,
  },
  normalText3: {
    color: "black",
    fontSize: 10,
    padding: "5px 0px",
  },
  normalText4: {
    padding: "5px 0px",
    color: "black",
    fontSize: 10,
    marginTop: "5px",
  },
  redText: {
    color: "red",
    fontSize: 14,
    marginBottom: "5px",
  },
  greenText: {
    color: "green",
    fontSize: 14,
    marginBottom: "5px",
  },
  smallText: {
    fontSize: 8,
    padding: "5px 0px",
  },
  smallBox: {
    backgroundColor: "#f5f2f7",
    padding: "2px 5px",
    borderRadius: "5px",
    marginBottom: "5px",
  },
  subTitleription: {
    color: "white",
    padding: "5px",
    fontSize: 9,
  },
  subTitle: {
    backgroundColor: "#44008f",
    color: "white",
    padding: "5px",
    fontSize: 9,
    textAlign: "center",
  },
  subBox: {
    border: "1px solid lightgray",
    borderRadius: "10px",
    margin: "1px 0px",
    overflow: "hidden",
  },
  subMenuData: {
    padding: "2px 0px",
    letterSpacing: ".3px",
    fontSize: 9,
  },
  subMenuTitle: {
    padding: "2px 0px",
    color: "gray",
    letterSpacing: ".3px",
    fontSize: 10,
  },
  mytext: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "bold",
  },
  imageContainer: {
    flexDirection: "row",
    alignItems: "center",
    margin: "0 2px",
    width: "50%",
  },
  imageBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0 5px",
    backgroundColor: "white",
    borderRadius: "5px",
    width: "60px",
    height: "60px",
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    padding: "1px",
    paddingTop: "10px",
    borderRadius: "5px",
    objectFit: "contain",
  },
  image1: {
    width: "95%",
    height: "95%",
    padding: "1px",
    borderRadius: "5px",
    objectFit: "contain",
    margin: "auto",
  },
  link: {
    fontSize: 8,
    color: "blue",
    textDecoration: "underline",
    textAlign: "left",
    paddingBottom: "10px",
    width: "80px",
  },
  shareDownload: {
    flexDirection: "row",
    alignItems: "center",
    padding: "20px",
  },
  page2: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    width: "100%",
    height: "100vh",
  },
  page3: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    width: "100%",
    height: "100vh",
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 12,
    marginBottom: "5px",
  },
  imageWrapper: {
    width: "50%",
    height: "17%",
    padding: "5px",
    border: ".5px solid #dddddd",
  },
  Section5: {
    backgroundColor: "#ffffff",
    width: "100%",
    height: "25%",
    padding: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  Box1: {
    width: "65%",
    padding: "10px",
  },
  SpecialBox: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "5px",
    fontSize: 10,
    marginBottom: "10px",
  },
  normalText: {
    fontSize: 10,
    color: "black",
    margin: "5px 0px",
  },
  normalTextA: {
    fontSize: 10,
    color: "black",
    margin: "5px 70px",
    textAlign: "right"
  },
  normalText1: {
    fontSize: 8,
    color: "black",
  },
  normalText5: {
    fontSize: 8,
    color: "gray",
    letterSpacing: ".3px",
    lineHeight: "1.4px",
  },
  normalText2: {
    fontSize: 9,
    color: "black",
  },
  normalText6: {
    fontSize: 9,
    color: "black",
    width: "50%",
  },
  Box2: {
    width: "35%",
    height: "100%",
    padding: "10px",
  },
  signatureContainer1: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    marginBottom: "3px",
  },
  signatureContainer2: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  signatureImage: {
    width: "50px",
    height: "50px",
    objectFit: "contain",
  },
  signatureImage1: {
    width: "100px",
    height: "50px",
    marginRight: "10px",
    objectFit: "contain",
  },
  mainCarImages: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
  pageImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  FlexyCase: {
    flexDirection: "row",
    alignItems: "center",
  },
  menuItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 8,
    paddingLeft: 10,
    paddingRight: 10,
    margin: "2px 0px",
    marginBottom: 2,
  },
  intactText: {
    color: "green",
  },
  scratchedText: {
    color: "orange",
  },
  brokenText: {
    color: "red",
  },
  defaultText: {
    color: 'black'
  },
  GrayText: {
    color: "gray",
    fontSize: 9,
    padding: "2px 0px",
  },
  specialGrayText: {
    color: "black",
    fontSize: 9,
    padding: "2px 0px",
    marginBottom: "5px",
  },
  Barcode: {
    height: "60px",
    width: "60px",
    objectFit: "contain",
    marginRight: "1px",
    padding: "1px",
  },
  BarCode1: {
    padding: "1px",
    height: "60px",
    borderRadius: "5px",
    width: "60px",
    objectFit: "contain",
    marginRight: "5px",
    border: "1px solid gray",
  },
  footer: {
    backgroundColor: "#44008f",
    padding: "10px",
    flexDirection: "row",
    justifyContent: "space-around",
    // marginTop: "10px",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  normalText7: {
    fontSize: 10,
    color: "white",
  },
});

export const CommercialVehiclePdfDocument = ({ user, generatedAt }) => {
  // console.log("images",user.images);

  const {
    color,
    licensePlate,
    make,
    model,
    variant,
    year,
    vehicleRunningCondition,
    chasis,
    fuelUsed,
    engineNumber,
    video,
    remarks,
    insurer,
    inspectorName,
    purpose,
    proposer,
    requestor,
    qcName,
    qcStatus,
    caseId,
    createdAt,
    inspectionDateTime,
    cusSign,
    executiveName,
    insuranceCompany,
    insuranceUpto,

    odometerType,

        // Commercial Vehicle-specific fields

        // Front
        commercialCowl,
        commercialBonnet,
        commercialBumper,
        commercialCabin,
        commercialHeadLightLt,
        commercialHeadLightRt,
        commercialIndicatorFrontRt,
        commercialIndicatorFrontLt,

        // Left Hand Side
        commercialLeftBody,
        commercialLeftDoor,

        // Rear
        commercialRearBody,
        commercialIndicatorRearLt,
        commercialIndicatorRearRt,

        // Right Hand Side
        commercialRightBody,
        commercialRightDoor,

        // Glasses
        commercialFrontWsGlass,
        commercialWindowGlassLt,
        commercialWindowGlassRt,
        commercialBackWSGlass,

        // Meter
        commercialOdometerType,
        commercialOdometer,
        commercialBattery,

        // Body / Exterior
        commercialBody,
        commercialSeat,
        commercialFuelTank,
        commercialLoader,
        commercialSilencer,

        // Tyres & Wheels
        commercialTyre,
        commercialWheelRims,

  } = user || {};

  // const imagesToShow = images.length > 8 ? images.slice(8) : images;

  const Barcode = `https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=${encodeURIComponent(
    video
  )}`;

  const updatedAtFormat = formatIsoDateTime(createdAt);

  const inspectionDateTimeFormat = formatIsoDateTime(inspectionDateTime);

  const getDynamicStyle = (value) => {
    switch (value) {
      case 'Dent':
      case 'Scratched':
        return styles.scratchedText;
      case 'Damaged':
      case 'Broken':
        return styles.brokenText;
      case 'Intact':
        return styles.intactText;
      default:
        return styles.defaultText;
    }
  };

  return (
    <Document>
      <Page size={{ width: 595.276, height: 1080 }} style={styles.page}>
        <View style={styles.Header}>
          <View style={styles.row}>
            {/* <Image src={LogoImg} style={styles.logo} /> */}
            <View style={styles.brandDetails}>
              {/* <Text style={styles.brandTitle}>Smart AI Inspection</Text> */}
              <Image src={SmartInspectLogo} style={styles.logImg} />
              <Text style={styles.description}>
                Unit No-11A, Office No. 411, 4th Floor
              </Text>
              <Text style={styles.description}>Amsri Square Next to Apollo Hospital</Text>
              <Text style={styles.description}>Hyderabad-500003, T S</Text>
            </View>
            <View style={styles.contactInfo}>
              <View style={styles.contactDetail}>
                <Text style={styles.contactTitle}>Phone Number:</Text>
                <Text style={styles.boldText1}>022-69719073</Text>
              </View>
              <View style={styles.contactDetail}>
                <Text style={styles.contactTitle}>Email:</Text>
                <Text style={styles.boldText1}>info@smartinspect.ai</Text>
              </View>
              <View style={styles.contactDetail}>
                <Image src={AdsumLogo} style={styles.logImg} />
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section2}>
          <View style={styles.MainFlexBox}>
            <View style={styles.flexMainBox}>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Insurer/Broker</Text>
                <Text style={styles.subMenuData}>
                  {insurer || 'NA'}
                </Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Company Reference No.</Text>
                <Text style={styles.subMenuData}>{caseId || 'NA'}</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}> Inspector</Text>
                <Text style={styles.subMenuData}>{inspectorName || 'NA'}</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}> Purpose</Text>
                <Text style={styles.subMenuData}>{purpose || 'NA'}</Text>
              </View>
            </View>
            <View style={styles.flexMainBox}>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Request Date & Time</Text>
                <Text style={styles.subMenuData}>{updatedAtFormat.date} {updatedAtFormat.time}</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Inspection Date & Time</Text>
                <Text style={styles.subMenuData}>{inspectionDateTimeFormat.date || 'NA'} {inspectionDateTimeFormat.time}</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}> Proposer</Text>
                <Text style={styles.subMenuData}>{proposer || 'NA'}</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Requestor</Text>
                <Text style={styles.subMenuData}>{requestor || 'NA'}</Text>
              </View>
            </View>
          </View>
          <Image
            // src="https://feeds.abplive.com/onecms/images/uploaded-images/2023/10/17/f2477a8b2c611a0f92fa7d3ac11d9ed81697514430233320_original.jpg?impolicy=abp_cdn&imwidth=640"
            src={user.images.length > 0 ? user.images[0] : "https://feeds.abplive.com/onecms/images/uploaded-images/2023/10/17/f2477a8b2c611a0f92fa7d3ac11d9ed81697514430233320_original.jpg?impolicy=abp_cdn&imwidth=640"}
            style={styles.MainCarImg}
          />
        </View>

        <View style={styles.section3}>
          <View style={styles.largeBox}>
            <View style={styles.CenteText}>
              <Text style={styles.BoldTitles}>VEHICLE DETAILS</Text>
              <Text style={styles.letterStyle}>
                Reg No.: {licensePlate}
              </Text>
            </View>
            <View style={styles.mainBlock}>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Make</Text>
                <Text style={styles.specialGrayText}>{make || "NA"}</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Model</Text>
                <Text style={styles.specialGrayText}>{model || "NA"}</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Vehicle Running Condition</Text>
                <Text style={styles.specialGrayText}>
                  {vehicleRunningCondition || "NA"}
                </Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Insurance Upto</Text>
                <Text style={styles.specialGrayText}>
                  {insuranceUpto || "NA"}
                </Text>
              </View>
            </View>
            <View style={styles.mainBlock}>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>MM/Year</Text>
                <Text style={styles.specialGrayText}>{year || "NA"}</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Chassis No.</Text>
                <Text style={styles.specialGrayText}>{chasis || "NA"}</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Fuel Used</Text>
                <Text style={styles.specialGrayText}>{fuelUsed || "NA"}</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Insurance Company</Text>
                <Text style={styles.specialGrayText}>{insuranceCompany || "NA"}</Text>
              </View>
            </View>
            <View style={styles.mainBlock}>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Color</Text>
                <Text style={styles.specialGrayText}>{color || "NA"}</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Engine No</Text>
                <Text style={styles.specialGrayText}>
                  {engineNumber || "NA"}
                </Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Odometer</Text>
                <Text style={styles.specialGrayText}>
                  {odometerType || "NA"}
                </Text>
              </View>
              {/* Added this to fit row, this is an empty, data if anything is necessary then we can update it */}
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}></Text>
                <Text style={styles.specialGrayText}> </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section4}>
            <View style={styles.mainBox}>

                {/* LEFT HAND SIDE */}
                <View style={styles.subBox}>
                    <Text style={styles.subTitle}>LEFT HAND SIDE</Text>
                    <View style={styles.menuItem}>
                        <Text>Left Body</Text>
                        <Text style={getDynamicStyle(commercialLeftBody)}>{commercialLeftBody || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Left Door</Text>
                        <Text style={getDynamicStyle(commercialLeftDoor)}>
                        {commercialLeftDoor || "NA"}
                        </Text>
                    </View>
                
                </View>
                
                {/* FRONT */}
                <View style={styles.subBox}>
                    <Text style={styles.subTitle}>FRONT</Text>
                    <View style={styles.menuItem}>
                        <Text>Cowl </Text>
                        <Text style={getDynamicStyle(commercialCowl)}>{commercialCowl || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Bonnet</Text>
                        <Text style={getDynamicStyle(commercialBonnet)}>{commercialBonnet || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Bumper/Mudguard</Text>
                        <Text style={getDynamicStyle(commercialBumper)}>{commercialBumper || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Cabin (Steel/Wooden)</Text>
                        <Text style={getDynamicStyle(commercialCabin)}>{commercialCabin || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Head Light LT</Text>
                        <Text style={getDynamicStyle(commercialHeadLightLt)}>{commercialHeadLightLt || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Head Light RT</Text>
                        <Text style={getDynamicStyle(commercialHeadLightRt)}>{commercialHeadLightRt || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Indicator Front RT </Text>
                        <Text style={getDynamicStyle(commercialIndicatorFrontRt)}>{commercialIndicatorFrontRt || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Indicator Front LT </Text>
                        <Text style={getDynamicStyle(commercialIndicatorFrontLt)}>{commercialIndicatorFrontLt || "NA"}</Text>
                    </View>
                </View>
                
                {/* METER  */}
                <View style={styles.subBox}>
                    <Text style={styles.subTitle}>METER</Text>
                    <View style={styles.menuItem}>
                        <Text>Odometer Type </Text>
                        <Text style={getDynamicStyle(commercialOdometerType)}>{commercialOdometerType || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Odometer </Text>
                        <Text style={getDynamicStyle(commercialOdometer)}>
                        {commercialOdometer || "NA"}
                        </Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Battery </Text>
                        <Text style={getDynamicStyle(commercialBattery)}>
                        {commercialBattery || "NA"}
                        </Text>
                    </View>
                
                </View>

                {/* Glasses */}
                <View style={styles.subBox}>
                    <Text style={styles.subTitle}>GLASSES</Text>
                    <View style={styles.menuItem}>
                        <Text>Front W/S Glass</Text>
                        <Text style={getDynamicStyle(commercialFrontWsGlass)}>{commercialFrontWsGlass || "NA"}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Window Glass LT</Text>
                        <Text style={getDynamicStyle(commercialWindowGlassLt)}>
                        {commercialWindowGlassLt || "NA"}
                        </Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Window Glass RT</Text>
                        <Text style={getDynamicStyle(commercialWindowGlassRt)}>
                        {commercialWindowGlassRt || "NA"}
                        </Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Back W/S Glass</Text>
                        <Text style={getDynamicStyle(commercialBackWSGlass)}>
                        {commercialBackWSGlass || "NA"}
                        </Text>
                    </View>
                </View>
            </View>

            <View style={styles.mainBox}>
                <View style={styles.subBox}>

                    {/* REAR */}
                    <Text style={styles.subTitle}>REAR</Text>
                    <View style={styles.menuItem}>
                        <Text>Rear Body</Text>
                        <Text style={getDynamicStyle(commercialRearBody)}>
                        {commercialRearBody || "NA"}
                        </Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Indicator Rear LT </Text>
                        <Text style={getDynamicStyle(commercialIndicatorRearLt)}>
                        {commercialIndicatorRearLt || "NA"}
                        </Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Indicator Rear RT </Text>
                        <Text style={getDynamicStyle(commercialIndicatorRearRt)}>{commercialIndicatorRearRt || 'NA'}</Text>
                    </View>
                
                </View>

                {/* RIGHT HAND SIDE */}
                <View style={styles.subBox}>
                    <Text style={styles.subTitle}>RIGHT HAND SIDE</Text>{" "}
                    <View style={styles.menuItem}>
                        <Text>Right Body </Text>
                        <Text style={getDynamicStyle(commercialRightBody)}>{commercialRightBody || 'NA'}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Right Door</Text>
                        <Text style={getDynamicStyle(commercialRightDoor)}>{commercialRightDoor || 'NA'}</Text>
                    </View>
                </View>

                {/* TYRE & WHEELS */}
                <View style={styles.subBox}>
                    <Text style={styles.subTitle}>TYRE & WHEELS</Text>{" "}
                    <View style={styles.menuItem}>
                        <Text>Tyre</Text>
                        <Text style={getDynamicStyle(commercialTyre)}>{commercialTyre || 'NA'}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Wheel Rims</Text>
                        <Text style={getDynamicStyle(commercialWheelRims)}>{commercialWheelRims || 'NA'}</Text>
                    </View>
                </View>

                {/* Body */}
                <View style={styles.subBox}>
                    <Text style={styles.subTitle}>BODY/EXTERIOR</Text>{" "}
                    <View style={styles.menuItem}>
                        <Text>Body (Steel/Wooden)</Text>
                        <Text style={getDynamicStyle(commercialBody)}>{commercialBody || 'NA'}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Seat</Text>
                        <Text style={getDynamicStyle(commercialSeat)}>{commercialSeat || 'NA'}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Fuel/Tank</Text>
                        <Text style={getDynamicStyle(commercialFuelTank)}>{commercialFuelTank || 'NA'}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Loader</Text>
                        <Text style={getDynamicStyle(commercialLoader)}>{commercialLoader || 'NA'}</Text>
                    </View>
                    <View style={styles.menuItem}>
                        <Text>Silencer</Text>
                        <Text style={getDynamicStyle(commercialSilencer)}>{commercialSilencer || 'NA'}</Text>
                    </View>
                </View>

            </View>
            <View style={styles.carDetailsBox}>
                <Image src={CarImg} style={styles.mainCarImage} />
                <View style={styles.caseInfo}>
                <Text style={styles.normalText4}>CASE ID : {caseId || 'NA'}</Text>
                <View style={styles.caseBox}>
                    <Text style={styles.normalText3}>Inspection status</Text>
                    
                    <Text style={qcStatus === 'Recommended' ? styles.greenText : 
                        qcStatus === 'Not Recommended' ? styles.redText : 
                        styles.normalText}>
                            {qcStatus || 'NA'}
                    </Text>
                    <View style={styles.smallBox}>
                    <Text style={styles.smallText}>Remarks /Reason</Text>
                    <Text style={styles.normalText3}>{remarks || "NA"}</Text>
                    </View>
                </View>
                </View>
            </View>
        </View>

        <View style={styles.Section5}>
          <View style={styles.Box2}>
            <View style={styles.shareDownload}>
              <Image src={Barcode} style={styles.Barcode} />
              <Text style={styles.normalText6}>Scan & Download Report</Text>
            </View>
            <View style={styles.shareDownload}>
              <Image src={PlayBtn} style={styles.BarCode1} />
              <Link src={video || "NA"} style={styles.link}>
                <Text style={styles.link}>Click to Watch</Text>
              </Link>
            </View>
          </View>
          <View style={styles.Box1}>
            <Text style={styles.normalText}>Declaration by Customer:</Text>
            <Text style={styles.normalText5}>
              I/We hereby confirm that the vehicle has been inspected in
              presence of me/my/our representative. I/We hereby confirm that the
              identification details and damages of vehicle as noted/photographs
              taken by the inspecting officer are correct. Nothing has been
              hidden/undisclosed. I/We agreed that Repair/Replacement of
              dented/crack parts & Repair Painting of dented/scratched panels as
              per this inspection photographs shall be excluded in event of any
              claim lodged during the policy period. I here certify that I have
              shown the same vehicle which I have to get insured and if later at
              the time of claim it is found that vehicle shown and accidental
              are different then no claim is payable to me. I here certify that
              I will not claim for damages existing in my vehicle whether same
              are mentioned in report or not if same are seen in photograph
              taken by the inspector.
            </Text>

            <View style={styles.signatureContainer1}>
              <Text style={styles.normalTextA} >Customer Signature:</Text>
              <Text style={styles.normalTextA} >Authorized Signature:</Text>
            </View>
            <View style={styles.signatureContainer2}>
              <Image src={cusSign || Sign} style={styles.signatureImage1} />
              <Image src={Stamp2} style={styles.signatureImage} />
            </View>
          </View>
        </View>

        <View style={styles.footer}>
          <Text style={styles.normalText7}>Executive Name: {executiveName || 'NA'}</Text>
          <Text style={styles.normalText7}>
            Report Generated At: {generatedAt.date} {generatedAt.time}
          </Text>
          <Text style={styles.normalText7}>QC Done by: {qcName || 'NA'}</Text>
        </View>

      </Page>

      <Page size={{ width: 595.276, height: 1080 }} style={styles.page2}>
      <View style={styles.Header}>
          <View style={styles.row}>
            {/* <Image src={LogoImg} style={styles.logo} /> */}
            <View style={styles.brandDetails}> 
              {/* <Text style={styles.brandTitle}>Smart AI Inspection</Text> */}
              <Image src={SmartInspectLogo} style={styles.logImg} />
              <Text style={styles.description}>
                Unit No-11A, Office No. 411, 4th Floor
              </Text>
              <Text style={styles.description}>Amsri Square Next to Apollo Hospital</Text>
              <Text style={styles.description}>Hyderabad-500003, T S</Text>
            </View>
            <View style={styles.contactInfo}>
              <View style={styles.contactDetail}>
                <Text style={styles.contactTitle}>Phone Number:</Text>
                <Text style={styles.boldText1}>022-69719073</Text>
              </View>
              <View style={styles.contactDetail}>
                <Text style={styles.contactTitle}>Email:</Text>
                <Text style={styles.boldText1}>info@smartinspect.ai</Text>
              </View>
              <View style={styles.contactDetail}>
                <Image src={AdsumLogo} style={styles.logImg} />
              </View>
            </View>
          </View>
        </View>

        {user?.images?.map((image, index) => (
          <View style={styles.imageWrapper} key={index}>
            <Link src={image}>
            <Image src={image} style={styles.pageImage} />
            </Link>
          </View>
        ))}
        {/* <ImageGallery images={user?.images} /> */}

        <View style={styles.footer}>
          <Text style={styles.normalText7}>Executive Name: {executiveName || 'NA'}</Text>
          <Text style={styles.normalText7}>
            Report Generated At: {generatedAt.date} {generatedAt.time}
          </Text>
          <Text style={styles.normalText7}>QC Done by: {qcName || 'NA'}</Text>
        </View>
      </Page>

    </Document>
  );
};

const generatePdfCommercialVehicle = async (data: any) => {
  try {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    // Format the date as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;
    // const formattedDate = now.toLocaleDateString();
    const formattedTime = now.toLocaleTimeString();
    const blob = await pdf(<CommercialVehiclePdfDocument user={data} generatedAt={{ date: formattedDate, time: formattedTime }} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${data.caseId}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

export default generatePdfCommercialVehicle;
