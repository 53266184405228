import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, Typography, Alert } from '@mui/material';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../../toolkit/hooks';
import { useLocation, useParams } from "react-router-dom";
import jwtAxios, { setAuthToken } from '@crema/services/auth/jwt-auth';
import uploadFile from 's3/s3.upload';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const steps = [
  "RHS FRONT 45 DEGREE",
  "FRONT 90 DEGREE",
  "FRONT UNDERARRIAGE",
  "FRONT OUTSIDE GLASS",
  "LHS FRONT 45 DEGREE",
  "LHS 90 DEGREE",
  "LHS REAR 45 DEGREE",
  "REAR 90 DEGREE",
  "UNDERCARRIAGE REAR",
  "OPEN DICKY",
  "RHS REAR 45 DEGREE",
  "RHS 90 DEGREE",
  "LHS FRONT 45 DEGREE",
  "ODOMETER READING",
  "W/S GLASS INSIDE",
  "CHASSIS NUMBER",
  "LHS REAR TYRE",
  "LHS REAR DOT NUMBER",
  "LHS FRONT TYRE",
  "LHS FRONT DOT NUMBER",
  "RHS REAR TYRE",
  "RHS REAR DOT NUMBER",
  "RHS FRONT TYRE",
  "RHS FRONT DOT NUMBER",
  "RC COPY 1",
  "RC COPY 2",
];

const SelfEvaluation = () => {
  const navigate = useNavigate(); // Initialize navigate hook
  const { id } = useParams();
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [cameraAccess, setCameraAccess] = useState<null | boolean>(null);
  const [currentStep, setCurrentStep] = useState(-1); // Track current step; -1 indicates vehicle details page
  const [vehicleData, setVehicleData] = useState<any>(null); // State to store fetched vehicle details
  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>(null); // State to store coordinates
  const [address, setAddress] = useState<string>(''); // State to store address
  const [isMobile, setIsMobile] = useState<boolean>(false); // State to check if device is mobile
  const videoRef = useRef<HTMLVideoElement | null>(null); // Ref for the video element
  const canvasRef = useRef<HTMLCanvasElement | null>(null); // Ref for the canvas element
  const capturedImages = useRef<HTMLCanvasElement[]>([]); // To store captured images
  const [timer, setTimer] = useState(300); // Timer in seconds (5 minutes)

  // Detect if the device is mobile
  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   if (/android|iPad|iPhone|iPod/.test(userAgent.toLowerCase())) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice = /android|iPad|iPhone|iPod/.test(userAgent.toLowerCase());
    // console.log("isMobileDevice", isMobileDevice);
    setIsMobile(isMobileDevice);
  }, []);

  // Function to request camera access
  const requestCameraAccess = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        // to check on the desktop uncomment this video: true
        // video: true 
        video: { facingMode: { exact: "environment" } } // Use back camera by default
      });
    try {
      const isMobile = /android|iPad|iPhone|iPod/.test(navigator.userAgent.toLowerCase());
      const constraints = {
        video: {
          facingMode: isMobile ? { exact: 'environment' } : { exact: 'user' }, // Use back camera on mobile, front camera otherwise
        },
        audio: false,
      };
  
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setCameraAccess(true);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
  
      if (error.name === 'NotAllowedError') {
        alert('Camera access denied. Please enable camera permissions in your browser settings.');
      } else if (error.name === 'NotFoundError') {
        alert('No camera found. Please ensure your device has a camera.');
      } else if (error.name === 'OverconstrainedError') {
        alert('Back camera not available. Please ensure your device supports this feature.');
      } else {
        alert('An unknown error occurred. Please check your browser settings.');
      }
  
      setCameraAccess(false);
    }
  };
  
  
  

  // Function to fetch the address using reverse geocoding
  const fetchAddress = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCJi9IR38yVxFmeir2yV6LZiZicrsZyIak`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setAddress(data.results[0].formatted_address); // Set the fetched address
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  // Function to get user's coordinates
  const getCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatLng({ lat: latitude, lng: longitude }); // Set coordinates
          fetchAddress(latitude, longitude); // Fetch address using coordinates
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const startSelfEvaluation = () => {
    setCurrentStep(0); // Start the self-evaluation steps
    requestCameraAccess(); // Request camera access immediately
    setTimer(300); // Reset timer to 5 minutes
    getCoordinates(); // Get user's location
  };

  const captureImage = async () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

        const currentDateTime = new Date().toLocaleString(); // Current date and time

        // Add text for date, time, and address on the canvas
        context.font = '16px Arial'; // Set font size and type
        context.fillStyle = 'white'; // Set text color to white for better contrast
        context.textAlign = 'right'; // Align text to the right
        context.fillText(currentDateTime, canvasRef.current.width - 10, canvasRef.current.height - 40); // Draw date and time
        context.fillText(address, canvasRef.current.width - 10, canvasRef.current.height - 20); // Draw address below

        const dataUrl = canvasRef.current.toDataURL('image/jpeg'); // Convert the canvas to a data URL
        const blob = dataURLtoBlob(dataUrl); // Convert the data URL to a Blob

        const key = `images/${id}/${Date.now()}_.jpg`;

        try {
          const fileUrl = await uploadFile(key, blob, 'image/jpeg');
          capturedImages.current[currentStep] = fileUrl;
          await updateVehicleImages();
          console.log("Image uploaded to:", fileUrl);
        } catch (error) {
          console.error("Error uploading image:", error);
        }

        // Move to the next step or complete
        if (currentStep < steps.length - 1) {
          setCurrentStep(currentStep + 1);
        } else {
          setCurrentStep(steps.length); // Mark as completed
          navigate(`/video-recording/${id}`); // Navigate to video recording page
        }
      }
    }
  };

  const dataURLtoBlob = (dataUrl: string) => {
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const updateVehicleImages = async () => {
    try {
      const formDataWithStatus = {
        images: capturedImages.current
      };

      await jwtAxios.put(`/api/case/${id}`, formDataWithStatus);
      console.log('Update successful');
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  useEffect(() => {
    if (timer > 0 && currentStep >= 0 && currentStep < steps.length) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (timer === 0) {
      setCurrentStep(-1);
      setCameraAccess(null);
      console.log('Timer reset due to timeout');
    }
  }, [timer, currentStep]);

  useEffect(() => {
    if (cameraAccess && videoRef.current && videoRef.current.srcObject === null) {
      navigator.mediaDevices.getUserMedia({ 
        // to check on the desktop uncomment this video: true
        // video: true 
        video: { facingMode: { exact: "environment" } } 
      }) // Request back camera again
        .then(stream => {
          videoRef.current!.srcObject = stream;
          videoRef.current!.play();
        })
        .catch(error => {
          console.error('Error accessing camera:', error);
        });
    }
  }, [cameraAccess]);

  useEffect(() => {
    const fetchLogin = async () => {
      const email = 'dev@gmail.com';
      const password = 'dev123';
      
      try {
        const { data } = await jwtAxios.post('auth/webuser/login', { email, password });

        localStorage.setItem('token', data.accessToken);
      } catch (error) {
        console.error("Error during auto login:", error);
      }

      try {
        const response = await jwtAxios.get(`api/case/${id}`);
        setVehicleData(response.data);
      } catch (error) {
        console.error("Error fetching vehicle details:", error);
      }
    };

    fetchLogin();
  }, []);

  return (
    <>
      <AppsContainer title={"Self Complete Form"} fullView>
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            py: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* Warning message for mobile device usage */}
          {!isMobile && !/iPhone/.test(navigator.userAgent) && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              Please use a mobile device for this evaluation.
            </Alert>
          )}

          {currentStep === -1 && (
            <>
              <Typography variant="h5" fontSize='24px' fontWeight='600' sx={{ mb: 3 }}>
                Vehicle Detail
              </Typography>
              <Box sx={{ mb: 2, width: '100%', px: 2 }}>
                <Typography>Name: {vehicleData?.name || 'Loading...'}</Typography>
                <Typography>Email: {vehicleData?.email || 'Loading...'}</Typography>
                <Typography>Number: {vehicleData?.number || 'Loading...'}</Typography>
                <Typography>Vehicle Name: {vehicleData?.vehicleName || 'Loading...'}</Typography>
                <Typography>Color: {vehicleData?.color || 'Loading...'}</Typography>
                <Typography>License Plate: {vehicleData?.licensePlate || 'Loading...'}</Typography>
                <Typography>Address: {vehicleData?.address || 'Loading...'}</Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ fontSize: '18px', py: 1, px: 3 }}
                onClick={startSelfEvaluation}
              >
                Start Self Evaluation
              </Button>
            </>
          )}

          {cameraAccess === false && (
            <Typography sx={{ color: 'red', mt: 2, mb: 2 }}>
              Camera permission denied. Please allow camera access in your browser settings.
            </Typography>
          )}

          {cameraAccess === true && currentStep >= 0 && currentStep < steps.length && (
            <>
              <Typography variant="h5" fontSize='20px' fontWeight='600' sx={{ mb: 3 }}>
                Step {currentStep + 1}: {steps[currentStep]}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '350px',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  margin: '0 auto',
                  mt: 2,
                  position: 'relative',
                }}
              >
                <video
                  ref={videoRef}
                  style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                  autoPlay
                  playsInline
                  muted
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2, fontSize: '16px' }}
                  onClick={captureImage}
                >
                  Capture Image for "{steps[currentStep]}"
                </Button>
              </Box>
              <Typography variant="h6" sx={{ mt: 3 }}>
                Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60}
              </Typography>
            </>
          )}

          {cameraAccess === true && currentStep >= steps.length && (
            <>
              <Typography variant="h6" sx={{ mt: 3, color: 'green' }}>
                All steps completed! Verification successful.
              </Typography>
              <Confetti />
            </>
          )}

          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </Container>
      </AppsContainer>
    </>
  );
};

export default SelfEvaluation;
