import React, { ReactNode } from 'react';
import FirebaseAuthProvider from '@crema/services/auth/firebase/FirebaseAuthProvider';
import JWTAuthAuthProvider from "../../services/auth/jwt-auth/JWTAuthProvider";

type Props = {
  children: ReactNode;
};

const AppAuthProvider = ({ children }: Props) => {
  return <JWTAuthAuthProvider>{children}</JWTAuthAuthProvider>;
};

export default AppAuthProvider;
