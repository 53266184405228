export const authRole = {
  Admin: ['admin'],
  User: ['user', 'admin'],
};

export const defaultUser: any = {
  uid: 'john-alex',
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: '/assets/images/avatar/A11.jpg',
};
export const allowMultiLanguage = true;
export const fileStackKey = 'Ach6MsgoQHGK6tCaq5uJgz';
export const initialUrl = '/dashboard'; // this url will open after login
