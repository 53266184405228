import React, { useEffect, useState, useMemo } from 'react';
import AppGridContainer from '@crema/components/AppGridContainer';
import { Fonts } from '@crema/constants/AppEnums';
import { Box, Button, Grid, Hidden, Slide, TextField, Autocomplete } from '@mui/material';
import {openSnackbar} from "../../../toolkit/actions/snackbar";
import {useDispatch} from "react-redux";
import AppScrollbar from '@crema/components/AppScrollbar';
import AppCard from '@crema/components/AppCard';
import uploadFile from "s3/s3.upload"
import jwtAxios, { setAuthToken } from "@crema/services/auth/jwt-auth/index"
import AppsHeader from "../../../@crema/components/AppsContainer/AppsHeader";
import AppSearchBar from "../../../@crema/components/AppSearchBar";
import AppsPagination from "../../../@crema/components/AppsPagination";
import AppsContent from "../../../@crema/components/AppsContainer/AppsContent";
import ListingTable from "./Listing/ListingTable";
import { messages } from '@crema/mockapi/fakedb';

const cats = ["banner", "mobileBanner"];
function ContactusImages() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [bannersList, setbannersList] = useState({
        Position: "null",
        Image: 'null',
    });
    const [search, setSearch] = useState("");
    const banners = useMemo(() => {
        return Object.entries(bannersList).map(([key, value]) => ({
            Position: key,
            Image: value,
        }));
    }, [bannersList, search]);
    const [update, setUpdate] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [bannerID, setBannerID] = useState('');
    const [formData, setFormData] = useState({
        image: '',
        position: 'banner',
    });

    const [errors, setErrors] = useState({});

    const updateTable = () => {

        jwtAxios.get( 'contactus' ).then((response) => {
            delete response.data._id
            response.data && setbannersList(response.data);
            setLoadingTable(false)
            console.log("loading the cats list", response.data)
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        // Remove specific error when editing the field
        setErrors({ ...errors, [name]: '' });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log("what to say?", file)
        if (file && file.type.startsWith('image/')) {
            setFormData({ ...formData, image: file });
            setErrors({ ...errors, "image": '' });
        } else {
            e.target.value = null;
            dispatch(openSnackbar('Only images allowed', 'error'));
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = {};
        // Check for empty fields
        if (!formData.image) {
            validationErrors.image = 'Image is required';
        }


        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // console.log(formData.bannerName+Date.now())
            const fileExtension = formData.image.name.split('.').pop();
            let sendData = formData
            try{
                const imageUrl = await uploadFile("contactUs/"+formData.bannerName+Date.now()+"."+fileExtension, formData.image); // Upload the image using uploadFile function
                sendData[formData.position] = imageUrl
                console.log(formData);
            } catch (e) {
                dispatch(openSnackbar("Some error occured please retry", 'error'));
                return
            }

            try{
                let url = 'contactus'
                let action = 'post'

                const response = await jwtAxios[action](url, sendData);

                let messages = update ? 'Banner Updated successfully' : 'Banner added successfully'
                dispatch(openSnackbar('Banner added successfully', 'success'));
                console.log(response)

                const fileInput = document.getElementById('image'); // Get the file input element by ID
                if (fileInput) {
                    fileInput.value = ''; // Set the value of the file input to an empty string to clear it
                }

                setFormData({
                    image: null,
                    position: 'banner',
                })
                updateTable()
                setUpdate(false)
            } catch(e) {
                dispatch(openSnackbar(e.response.data.message, 'error'));
            }
        }
    };
    const handlePositionChange = (event, value) => {
        setFormData({ ...formData, position: value });
        setErrors({ ...errors, position: '' });
    };
    useEffect(() => {
        updateTable()
    }, []);

    const onPageChange = (e) => {
        console.log(e)
    }

    const delete_fun = (data) => {
        console.log(data)
    }
    const edit_fun = (data) => {
        setErrors({})
        setBannerID(data._id)
        setFormData({
            bannerName: data["Name"],
            bannerImage: '',
            image: null,
            position: data["Position"],
            redirectTo: data["Redirect To"]
        })
        setUpdate(true)
        console.log(data)
    }

    return (
        <>
            <Box
                component='h2'
                sx={{
                    fontSize: 16,
                    color: 'text.primary',
                    fontWeight: 'fontWeightBold',
                    mb: {
                        xs: 2,
                        lg: 4,
                    },
                }}
            >
                Contact Us Images
            </Box>
            <Slide direction='right' in mountOnEnter unmountOnExit>
                <Grid item xs={12} lg={8}>
                    <AppCard>
                        <form onSubmit={handleSubmit}>


                            <br /><br />
                            <Autocomplete
                                single
                                label='Position'
                                id='position'
                                name='position'
                                options={cats}
                                value={formData.position}
                                onChange={handlePositionChange}
                                getOptionLabel={(option) => option}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Position'
                                        placeholder='Select Position of the banner'
                                        error={!!errors.position}
                                        helperText={errors.position}
                                    />
                                )}
                            />
                            <br /><br />

                            <TextField
                                className='mt-8 mb-16 mx-6'
                                label='Image'
                                type='file'
                                id='image'
                                name='image'
                                variant='outlined'
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                onChange={handleImageChange}
                                error={!!errors.image}
                                helperText={errors.image}
                            />
                            <br />
                            <br />

                            <Button
                                sx={{
                                    display: 'block',
                                    minWidth: 100,
                                }}
                                color='primary'
                                variant='contained'
                                type='submit'
                            >
                                Update
                            </Button>
                        </form>
                    </AppCard>
                </Grid>
            </Slide>
            <Slide direction='right' in mountOnEnter unmountOnExit>
                        <Grid item xs={12} lg={9}>
                            <AppCard
                                title={
                                    <AppsHeader>
                                        <Box
                                            display='flex'
                                            flexDirection='row'
                                            alignItems='center'
                                            width={1}
                                            justifyContent='space-between'
                                        >
                                            <AppSearchBar
                                                iconPosition='right'
                                                overlap={false}
                                                onChange={(event) =>
                                                    setSearch(event.target.value)
                                                }
                                                placeholder={"Search Here"}
                                            />
                                            <Box
                                                display='flex'
                                                flexDirection='row'
                                                alignItems='center'
                                                justifyContent='flex-end'
                                            >
                                                <Hidden smDown>
                                                    <AppsPagination
                                                        rowsPerPage={10}
                                                        count={10}
                                                        page={page}
                                                        onPageChange={onPageChange}
                                                    />
                                                </Hidden>
                                            </Box>
                                        </Box>
                                    </AppsHeader>
                                }
                                headerStyle={{ p: 0 }}
                                contentStyle={{ p: 0 }}
                            >
                                <AppsContent
                                    sx={{
                                        paddingTop: 2.5,
                                        paddingBottom: 2.5,
                                    }}
                                >
                                    <ListingTable delete_fun={delete_fun} edit_fun={edit_fun} tabledata={banners} loading={loadingTable} phasee={"all"} />
                                </AppsContent>
                                <Hidden smUp>
                                    <AppsPagination
                                        rowsPerPage={10}
                                        count={10}
                                        page={page}
                                        onPageChange={onPageChange}
                                    />
                                </Hidden>
                            </AppCard>
                        </Grid>
                    </Slide>
        </>
    );
}

export default ContactusImages;
