import React, { useEffect, useMemo, useState } from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import { useAppDispatch, useAppSelector } from '../../../toolkit/hooks';
import { getCustomers } from '../../../toolkit/actions';
import jwtAxios, { setAuthToken } from "../../../@crema/services/auth/jwt-auth";
import { openSnackbar } from "../../../toolkit/actions/snackbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContactDetail from "../../apps/Contact/ContactDetail";
import { Button, Hidden, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import axios from 'axios';
// import { downloadPdf } from '@crema/helpers/FileHelper';
import generatePdf from 'modules/ecommerce/Admin/Component/Car/CarPdfDownload';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import generatePdfTwoWheeler from './Component/TwoWheeler/TwoWheelerPdfDownload';
import generatePdfFarmEquipment from './Component/FarmEquipment/FarmEquipmentPdfDownload';
import generatePdfThreeWheeler from './Component/ThreeWheeler/ThreeWheelerPdfDownload';
import generatePdfCommercialVehicle from './Component/CommercialVehicle/CommercialVehiclePdfDownload';
import generatePdfConstructionEquipment from './Component/ConstructionEquipment/ConstructionEquipmentPdfDownload';


const CaseReport = () => {
  const phase = "all";
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const [customerCount, setCustomerCount] = useState(60)
  const [page, setPage] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false)
  const [isShowDetail, onShowDetail] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [videoAvailable, setVideoAvailable] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sellers = searchParams.get('seller');
  const report = !!searchParams.get('report');

  const rowsPerPage = 10;

  const [selectedContact, setSelectedContact] = useState(
    {}
  );
  const [search, setSearch] = useState("");
  const onViewContactDetail = (contact) => {
    setSelectedContact(contact);
    onShowDetail(true);
  };




  const [customerList, setCustomerList] = useState([])
  const [customerCSV, setCustomerCSV] = useState(null)
  const customers = useMemo(() => {
    if (customerList.length > 0) {
      return customerList
        .filter(customer => customer.firstName.toLowerCase().includes(search.toLowerCase()))
        .map(customer => {
          let customerDetails = {}
          if (report) {
            customerDetails = {
              "Credential ID": customer.credentialID,
              "Name": `${customer.firstName} ${customer.lastName}`,
              "Status": `<span style="color: ${customer.status === "active" ? 'blue' : 'red'}">${customer.status}</span>`,
              "Email": customer.email,
              "Company Name": customer.companyName,
              "State": customer.state,
              "City": customer.city,
              "Zip Code": customer.zipCode,
              "Address": customer.address,
              "Phone Number": customer.phoneNumber,
              "Employer ID": customer.employerId,
              "Seller Permit": customer.sellerPermit,
              "Hear About Us": customer.hearAboutUs,
              "Selling Platform": customer.sellingPlatform.join(", "),
            };
          } else {
            customerDetails = {
              "Name": `${customer.firstName} ${customer.lastName}`,
              "Credits": customer.credits,
              "Status": `<span style="color: ${customer.status === "active" ? 'blue' : 'red'}">${customer.status}</span>`,
              "Email": customer.email,
              "Company Name": customer.companyName,
              "Phone Number": customer.phoneNumber,
              "Seller Permit": customer.sellerPermit,
            };
          }
          return customerDetails;
        });
    } else {
      return [];
    }
  }, [customerList, search]);


  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
  }, []);


  const handleDownloadVideo = async (id) => {

    const exists = await checkVideoExists(id);
    
    if(exists) {
      const s3BucketUrl = 'https://smartinspection.s3.ap-south-1.amazonaws.com/';
      const videoUrl = `${s3BucketUrl}images/${id}/annotated_${id}.mp4`;
    
      // Create an anchor element and simulate a click to start the download
      const link = document.createElement('a');
      link.href = videoUrl;
      link.target = '_blank';
      link.download = `annotated_${id}.mp4`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else {
      alert('Annotated video not available.');
    }
  };

  const fetchData = () => {
    let config = {
      method: 'get',
      url: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/api/case',
      headers: {
        'apikey': 'c747b51cc1b18e6e9f4fdd72d6ada6c8',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    axios.request(config)
      .then((response) => {
        const completedCases = response.data.data.caseList.filter(caseItem => (caseItem.caseStatus !== 'Pending') && caseItem.name !== '');
        const sortedCases = completedCases.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setTableData(sortedCases)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const checkVideoExists = async (id) => {
    const s3BucketUrl = `https://smartinspection.s3.ap-south-1.amazonaws.com/images/${id}/annotated_${id}.mp4`;
  
    try {
      await axios.head(s3BucketUrl);
      return true; // Video exists
    } catch (error) {
      return false; // Video does not exist
    }
  };

  useEffect(() => {
    const checkAllVideos = async () => {
      const availability = {};

      for (const caseItem of tableData) {
        const exists = await checkVideoExists(caseItem._id);
        availability[caseItem._id] = exists;
      }

      setVideoAvailable(availability);
    };

    checkAllVideos();
  }, [tableData]);
  

  const showCustomer = (data, edit) => {
    const email = data.Email
    const customerToShow = customerList.filter(cus => cus.email === email);
    console.log(customerToShow[0])
    if (report) {
      onViewContactDetail(customerToShow[0]);
    } else {
      const goUrl = edit ? '/edituser' : '/user'

      navigate(goUrl, {
        state: {
          contact: customerToShow
        }
      })
    }
  }
  const onPageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    // dispatch(getCustomers(search, page));
  }, [dispatch, search, page]);

  const onSearchCustomer = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const updateTable = () => {
    let searchURL = `user?phase=${phase}&page=${page + 1}&search=${search}`
    if (sellers) {
      searchURL = `user?phase=${phase}&page=${page + 1}&search=${search}&seller=${sellers}`
    }
    let searchCSVURL = `usercsv?phase=${phase}&page=${page + 1}&search=${search}`
    if (sellers) {
      searchCSVURL = `usercsv?phase=${phase}&page=${page + 1}&search=${search}&seller=${sellers}`
    }

    jwtAxios.get(searchURL).then((response) => {
      setCustomerList(response.data.users);
      setLoadingTable(false);
      setCustomerCount(response.data.total)
      console.log("loading the customers list", response.data)
    });
    jwtAxios.get(searchCSVURL).then((response) => {
      setCustomerCSV(response.data);
      console.log("loading the users csv", response.data)
    });

  }

  useEffect(() => {
    // if(search.length > 2 || search.length === 0) {
    //     updateTable()
    // }
  }, [search, phase, page, sellers])


  const delete_fun = (data) => {
    console.log(data)

    jwtAxios.put('user', { credentialID: data["_id"], status: "not approved" }).then((response) => {
      console.log("done not approve", response.data)
    });
    onShowDetail(false);
    dispatch(openSnackbar('Application denied of user: ' + data.firstName, 'success'));
    updateTable()
  }

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleConfirmDelete = (data) => {
    setUserToDelete(data);
    setOpenConfirmDialog(true);
  };

  const handleDelete = () => {
    const id = userToDelete.Email;
    console.log(customerList)
    const customerToShow = customerList.filter(cus => cus.email === id);
    if (userToDelete) {
      jwtAxios.delete('user/' + customerToShow[0]["_id"]).then((response) => {
        console.log("done not approve", response.data);
        onShowDetail(false);
        dispatch(openSnackbar('Application denied of user: ' + userToDelete.Name, 'success'));
        updateTable();
      });
    }
    setOpenConfirmDialog(false);
    setUserToDelete(null);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    setUserToDelete(null);
  };

  const edit_fun = (data, edit = false) => {
    const id = data.Email;
    console.log(customerList)
    const customerToShow = customerList.filter(cus => cus.email === id);
    console.log(customerToShow[0])
    if (report) {
      onViewContactDetail(customerToShow[0]);
    } else {
      const goUrl = edit ? '/edituser' : '/user'

      navigate(goUrl, {
        state: {
          contact: customerToShow
        }
      })
    }
  }

  const handleCompleteManually = (data) => {
    if(data.caseId) {
      navigate(`/smartinspectai/manualForm/${data.caseId}`, { state: 
        { 
          caseData: data,
          userDetails: {
            name: data.name,
            email: data.email,
            number: data.number,
            vehicleName: data.vehicleName,
            color: data.color,
            licensePlate: data.licensePlate,
            address: data.address,
            make: data.make,
            model: data.model,
            variant: data.variant,
            year: data.year,
            chasis: data.chasis,
            fuelUsed: data.fuelUsed,
            engineNumber: data.engineNumber,
            odometerType: data.odometerType,
            images: data.images,
            video: data.video,
            cusSign: data.cusSign,
            executiveName: data.executiveName,
            insurer: data.insurer,
            inspectorName: data.inspectorName,
            purpose: data.purpose,
            proposer: data.proposer,
            requestor: data.requestor,
            qcName: data.qcName,
            remarks: data.remarks,
            frontTyreRhs: data.frontTyreRhs,
            frontTyreLhs: data.frontTyreLhs,
            rearTyreRhs: data.rearTyreRhs,
            rearTyreLhs: data.rearTyreLhs,
            insuranceCompany: data.insuranceCompany,
            insuranceUpto: data.insuranceUpto,
          }
        } 
      })
    }

    else {
      alert("Invalid CaseId ! Please check ");
    }
  };

  const filteredCases = tableData.filter(caseItem =>{
    // caseItem.name.toLowerCase().includes(search.toLowerCase())
    const searchTerm = search.toLowerCase();
    return (
      caseItem.name.toLowerCase().includes(searchTerm) ||
      caseItem.licensePlate.toLowerCase().includes(searchTerm) ||
      caseItem.caseId.toLowerCase().includes(searchTerm)
    )
  });

  const paginatedCases = filteredCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  return (
    <>
      <AppsContainer
        title={"Case Report"}
        fullView
      >
        <AppsHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
            }}
          >
            <AppSearchBar
              iconPosition='right'
              overlap={false}
              onChange={onSearchCustomer}
              placeholder={messages['common.searchHere']}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
              }}
            >
              <Button onClick={() => { console.log("helo") }}>Download CSV</Button>
              <Hidden smDown>
                <AppsPagination
                  rowsPerPage={rowsPerPage}
                  count={filteredCases.length}
                  page={page}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </Box>
          </Box>
        </AppsHeader>

        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <Table stickyHeader className='table'>
            <TableHead>
              <TableCell>Case Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Vehicle Name</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>License Plate</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Download Annotated Video</TableCell>
              <TableCell>Complete Manually</TableCell>
              <TableCell>Download Report</TableCell>
            </TableHead>
            <TableBody>
              {paginatedCases.map((data) => (
                <TableRow>
                  {/* {data.name !== "" && data.caseStatus === 'Completed' ? <> */}
                    <TableCell>{data.caseId}</TableCell>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>{data.number}</TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell align='left'>
                        {dayjs(data.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{data.vehicleName}</TableCell>
                    <TableCell>{data.color}</TableCell>
                    <TableCell>{data.licensePlate}</TableCell>
                    <TableCell>{data.address}</TableCell>
                    <TableCell>{data.caseStatus}</TableCell>
                    <TableCell>
                      <IconButton style={{ color: videoAvailable[data._id] ? 'green' : 'gray' }} onClick={() => handleDownloadVideo(data._id)}>
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Button
                        sx={{ display: 'block', minWidth: 100 }}
                        color='primary'
                        variant='contained'
                        type='button'
                        onClick={() => handleCompleteManually(data)}
                      >
                        Complete Manually
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button 
                          onClick={() => {
                            if (data.caseType === 'Car') {
                              generatePdf(data);
                            } else if (data.caseType === 'Two Wheeler') {
                              generatePdfTwoWheeler(data);
                            } else if (data.caseType === 'Farm Equipment') {
                              generatePdfFarmEquipment(data);
                            } else if (data.caseType === 'Three Wheeler') {
                              generatePdfThreeWheeler(data);
                            } else if (data.caseType === 'Commercial Vehicle') {
                              generatePdfCommercialVehicle(data);
                            } else if (data.caseType === 'Construction Equipment') {
                              generatePdfConstructionEquipment(data);
                            } else {
                              // Handle other cases or default behavior
                              alert('Unknown case type');
                              console.log('Unknown case type');
                            }
                          }}
                          >
                        Download Report
                      </Button>
                    </TableCell>
                  {/* </> : null} */}
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </AppsContent>

        <Hidden smUp>
          <AppsPagination
            rowsPerPage={rowsPerPage}
            count={filteredCases.length}
            page={page}
            onPageChange={onPageChange}
          />
        </Hidden>
      </AppsContainer>

      <ContactDetail
        selectedContact={selectedContact}
        isShowDetail={isShowDetail}
        onShowDetail={onShowDetail}
        delete_fun={delete_fun} delete_name={"Decline"} edit_name={"Approve"} edit_fun={edit_fun}
      />

      <AppInfoView />

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user ({userToDelete?.Name})?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CaseReport;
