import React from 'react';
import { useLocation } from 'react-router-dom';
import TwoWheeler from './Component/TwoWheeler/TwoWheeler';
import FarmEquipment from './Component/FarmEquipment/FarmEquipment';
import ThreeWheeler from './Component/ThreeWheeler/ThreeWheeler';
import CommercialVehicle from './Component/CommercialVehicle/CommercialVehicle';
import ConstructionEquipment from './Component/ConstructionEquipment/ConstructionEquipment';
import Car from './Component/Car/Car';


const Form1 = () => {
  const location = useLocation();
  const { state } = location;
  const caseData = state?.caseData;
  const userDetails = state?.userDetails;

  // console.log("CaseData", caseData);
  
  if(caseData.caseType === 'Car'){
    return (
      <Car caseData={caseData} userDetails={userDetails} />
    )
  }

  else if (caseData.caseType === 'Two Wheeler'){
    return (
      <TwoWheeler caseData={caseData} userDetails={userDetails} />
    )
  }

  else if (caseData.caseType === 'Farm Equipment') {
    return (
      <FarmEquipment caseData={caseData} userDetails={userDetails} />
    )
  }

  else if (caseData.caseType === 'Three Wheeler') {
    return (
      <ThreeWheeler caseData={caseData} userDetails={userDetails} />
    )
  }

  else if (caseData.caseType === 'Commercial Vehicle') {
    return (
      <CommercialVehicle caseData={caseData} userDetails={userDetails} />
    )
  }

  else if (caseData.caseType === 'Construction Equipment') {
    return (
      <ConstructionEquipment caseData={caseData} userDetails={userDetails} />
    )
  }

  else {
    return (
      <p>Wrong Vehicle Type ! Need to update</p>
    )
  }
};

export default Form1;
