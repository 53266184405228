import React from 'react';
import Box from '@mui/material/Box';
import IntlMessages from '@crema/helpers/IntlMessages';
import BusinessIcon from '@mui/icons-material/Business';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Fonts } from '@crema/constants/AppEnums';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface Props {
  contact: any;
}
const OtherDetails = (props: Props) => {
  const { contact } = props;

  return (
    <Box
      sx={{
        pb: 5,
      }}
    >
      <Box
        component='h4'
        sx={{
          mb: 4,
          fontWeight: Fonts.SEMI_BOLD,
        }}
      >
        <IntlMessages id='common.otherDetails' />
      </Box>

      <div>
        <Box
          sx={{
            mb: { xs: 2, md: 3 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <BusinessIcon
            sx={{
              fontSize: 24,
              color: (theme) => theme.palette.text.secondary,
            }}
          />
          <Box
            sx={{
              ml: 3.5,
            }}
          >
            {contact.city ? (
                contact.city + ", " + contact.state
            ) : (
              <IntlMessages id='common.na' />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            mb: { xs: 2, md: 3 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <HomeOutlinedIcon
            sx={{
              fontSize: 24,
              color: (theme) => theme.palette.text.secondary,
            }}
          />
          <Box
            sx={{
              ml: 3.5,
            }}
          >
            {contact.address ? (
              contact.address
            ) : (
              <IntlMessages id='common.na' />
            )}
          </Box>
        </Box>
          <Box
          sx={{
            mb: { xs: 2, md: 3 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              color: "gray",
            }}
          >Seller Permit:</span>
          <Box
            sx={{
              ml: 3.5,
            }}
          >
            {contact.sellerPermit ? (
                <a href={contact.sellerPermit} target={"new"}>Download</a>
            ) : (
              <IntlMessages id='common.na' />
            )}
          </Box>
        </Box>
          <Box
              sx={{
                  mb: { xs: 2, md: 3 },
                  display: 'flex',
                  alignItems: 'center',
              }}
          >
              <ShoppingCartIcon
                  sx={{
                      fontSize: 24,
                      color: (theme) => theme.palette.text.secondary,
                  }}
              />
          <Box
              sx={{
                  ml: 3.5,
              }}
          >
              {contact.sellingPlatform ? (
                  contact.sellingPlatform.join(", ")
              ) : (
                  <IntlMessages id='common.na' />
              )}
          </Box>
    </Box>
          <Box
              sx={{
                  mb: { xs: 2, md: 3 },
                  display: 'flex',
                  alignItems: 'center',
              }}
          >
              <ShoppingCartIcon
                  sx={{
                      fontSize: 24,
                      color: (theme) => theme.palette.text.secondary,
                  }}
              /> references
              <Box
                  sx={{
                      ml: 3.5,
                  }}
              >
                  <pre>
                      {contact.references ? (
                          contact.references.map((ref) => {
                              return `${ref.companyName} - ${ref.website} - ${ref.phoneNumber}\n`
                          })
                      ) : (
                          <IntlMessages id='common.na' />
                      )}
                  </pre>
              </Box>
          </Box>

      </div>
    </Box>
  );
};

export default OtherDetails;
