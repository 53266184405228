import React from 'react';
import Logo from '../../../assets/icon/something-wrong.svg';

import { useTheme } from '@mui/material';

const ErrorIcon = () => {
  const theme = useTheme();
  return <Logo />;
};

export default ErrorIcon;
