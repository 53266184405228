import React, {useEffect, useMemo, useState} from 'react';
import jwtAxios, { setAuthToken } from "@crema/services/auth/jwt-auth/index"
import AppGridContainer from '@crema/components/AppGridContainer';
import { Fonts } from '@crema/constants/AppEnums'; 
import {Box, Button, Grid, Hidden, Slide, TextField} from '@mui/material';
import AppScrollbar from '@crema/components/AppScrollbar';
import AppCard from '@crema/components/AppCard';
import {openSnackbar} from "../../../toolkit/actions/snackbar";
import {useDispatch} from "react-redux";
import AppsHeader from "../../../@crema/components/AppsContainer/AppsHeader";
import AppSearchBar from "../../../@crema/components/AppSearchBar";
import AppsPagination from "../../../@crema/components/AppsPagination";
import AppsContent from "../../../@crema/components/AppsContainer/AppsContent";
import ListingTable from "./Listing/ListingTable";
import {useIntl} from "react-intl";

function Category() {
    const { messages } = useIntl();
    const [page, setPage] = useState(0);
    const [categoriesList, setCategoriesList] = useState([]);
    const [search, setSearch] = useState("");
    const categories = useMemo(() => {
        return categoriesList
            .filter(cats => cats.categoryName.toLowerCase().includes(search.toLowerCase()))
            .map(cats => ({ Name: cats.categoryName, Categoryid: cats._id,  }));
    }, [categoriesList, search]);
    const [oldCategoryID, setOldCategoryID] = useState('');
    const [categoryID, setCategoryID] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [update, setUpdate] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [errors, setErrors] = useState({
        categoryID: '',
        categoryName: ''
    });
    const dispatch = useDispatch();
    const updateTable = () => {

        jwtAxios.get( 'category' ).then((response) => {
            setCategoriesList(response.data);
            setLoadingTable(false)
            console.log("loading the cats list", response.data)
        });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!categoryID || !categoryName) {
            setErrors({
                categoryID: !categoryID ? 'Category ID is required' : '',
                categoryName: !categoryName ? 'Category Name is required' : ''
            });
            dispatch(openSnackbar('Please fill in all fields', 'error'));

            return;
        }

        try {

            let url = update ? `category/${oldCategoryID}` : 'category'
            let action = update ? 'put' : 'post'
            const response = await jwtAxios[action](url, {
                _id: categoryID,
                categoryName: categoryName,
            });
            let message = update ? 'Category updated successfully' : 'Category added successfully'
            dispatch(openSnackbar(message, 'success'));


            setCategoryID("");
            setCategoryName("");
            console.log(response.data);
            setUpdate(false)
            updateTable()
        } catch (error) {
            console.error(error);
        }
    };



    useEffect(() => {
        updateTable()
    }, []);

    const onPageChange = (e) => {
        console.log(e)
    }

    const delete_fun = (data) => {
        console.log(data)
    }
    const edit_fun = (data) => {
        setOldCategoryID(data.CategoryID)
        setCategoryID(data.CategoryID)
        setCategoryName(data.Name)
        setUpdate(true)
        console.log(data)
    }

    return (
        <>
            <Box
                component='h2'
                sx={{
                    fontSize: 16,
                    color: 'text.primary',
                    fontWeight: Fonts.SEMI_BOLD,
                    mb: {
                        xs: 2,
                        lg: 4,
                    },
                }}
            >
                {'Category'}
            </Box>
            <Slide direction='right' in mountOnEnter unmountOnExit>
                <Grid item xs={12} lg={8}>
                    <AppScrollbar style={{ height: 'auto', marginBottom: "6vh" }}>
                        <AppCard>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    className='mt-8 mb-16 mx-6 pb-16'
                                    label='Category ID'
                                    autoFocus
                                    id='categoryID'
                                    name='categoryID'
                                    variant='outlined'
                                    fullWidth
                                    value={categoryID}
                                    onChange={(e) => {
                                        setCategoryID(e.target.value)
                                        setErrors({
                                            ...errors,
                                            categoryID: !e.target.value ? 'Category ID is required' : '',
                                        });
                                    }}
                                    error={!!errors.categoryID}
                                    helperText={errors.categoryID}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    className='mt-8 mb-16 mx-6'
                                    label='Category Name'
                                    id='categoryName'
                                    name='categoryName'
                                    variant='outlined'
                                    fullWidth
                                    value={categoryName}
                                    onChange={(e) => {
                                        setCategoryName(e.target.value)
                                        setErrors({
                                            ...errors,
                                            categoryName: !e.target.value ? 'Category Name is required' : ''
                                        });
                                    }}
                                    error={!!errors.categoryName}
                                    helperText={errors.categoryName}
                                />
                                <br/>
                                <br/>
                                <Button
                                    sx={{
                                        display: 'block',
                                        minWidth: 100,
                                    }}
                                    color='primary'
                                    variant='contained'
                                    type='submit'
                                >
                                    {update ? "Update" : "Submit"}
                                </Button>
                            </form>
                        </AppCard>
                    </AppScrollbar>
                    <Slide direction='right' in mountOnEnter unmountOnExit>
                        <Grid item xs={12} lg={9}>
                            <AppCard
                                title={
                                    <AppsHeader>
                                        <Box
                                            display='flex'
                                            flexDirection='row'
                                            alignItems='center'
                                            width={1}
                                            justifyContent='space-between'
                                        >
                                            <AppSearchBar
                                                iconPosition='right'
                                                overlap={false}
                                                onChange={(event) =>
                                                    setSearch(event.target.value)
                                                }
                                                placeholder={"Search Here"}
                                            />
                                            <Box
                                                display='flex'
                                                flexDirection='row'
                                                alignItems='center'
                                                justifyContent='flex-end'
                                            >
                                                <Hidden smDown>
                                                    <AppsPagination
                                                        rowsPerPage={10}
                                                        count={10}
                                                        page={page}
                                                        onPageChange={onPageChange}
                                                    />
                                                </Hidden>
                                            </Box>
                                        </Box>
                                    </AppsHeader>
                                }
                                headerStyle={{ p: 0 }}
                                contentStyle={{ p: 0 }}
                            >
                                <AppsContent
                                    sx={{
                                        paddingTop: 2.5,
                                        paddingBottom: 2.5,
                                    }}
                                >
                                    <ListingTable delete_fun={delete_fun} edit_fun={edit_fun} tabledata={categories} loading={loadingTable} />
                                </AppsContent>
                                <Hidden smUp>
                                    <AppsPagination
                                        rowsPerPage={10}
                                        count={10}
                                        page={page}
                                        onPageChange={onPageChange}
                                    />
                                </Hidden>
                            </AppCard>
                        </Grid>
                    </Slide>
                </Grid>
            </Slide>
        </>
    );
}

export default Category;
