import { authRole } from '@crema/constants/AppConst';

export const getUserFromAuth0 = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.User,
    };
  return user;
};

export const getUserFromFirebase = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : 'Admin',
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : '/assets/images/avatar/A11.jpg',
      role: authRole.User,
    };
  return user;
};
export const getUserFromAWS = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Admin',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.User,
    };
  return user;
};

export const getUserFromJwtAuth = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user.avatar,
      role: authRole.User,
    };
  return user;
};

// Function to get the user details from localStorage and check if they are authenticated
export const getUserFromLocalStorage = (user : any) => {
  // Retrieve user details from localStorage
  const userData = localStorage.getItem('userDetails');
  
  // Check if the user details are present
  if (userData) {
    // Parse the user details from JSON
    const user = JSON.parse(userData);

    // Check if the user has a valid UID and email, and if the email is verified
    if (user._id && user.email) {
      return {
        id: 1,
        uid: user._id,
        displayName: user.name ? user.displayName : 'Admin',
        email: user.email,
        photoURL: user.photoURL ? user.photoURL : '/assets/images/avatar/A11.jpg',
        role: authRole.User,
      };
    }
  }

  // If no valid user data is found, return null
  return null;
};
