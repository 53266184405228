import React from 'react';
import Box from '@mui/material/Box';
import IntlMessages from '@crema/helpers/IntlMessages';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import { Fonts } from '@crema/constants/AppEnums';
import { ContactObjType } from '@crema/types/models/apps/Contact';
import BadgeIcon from '@mui/icons-material/Badge';
interface Props {
  contact: any;
}
const PersonalDetails = (props: Props) => {
  const { contact } = props;

  return (
    <Box
      sx={{
        pb: 5,
      }}
    >
      <Box
        component='h4'
        sx={{
          mb: 4,
          fontWeight: Fonts.SEMI_BOLD,
        }}
      >
        <IntlMessages id='contactApp.personalDetails' />
      </Box>

      <div>
        <Box
          sx={{
            mb: { xs: 2, md: 3 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <EmailOutlinedIcon
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          />
          <Box
            sx={{
              ml: 3.5,
            }}
          >
            {contact.email}
          </Box>
        </Box>

        <Box
          sx={{
            mb: { xs: 2, md: 3 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PhoneOutlinedIcon
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          />
          <Box
            sx={{
              ml: 3.5,
            }}
          >
            {contact.phoneNumber}
          </Box>
        </Box>

        <Box
          sx={{
            mb: { xs: 2, md: 3 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LanguageIcon
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          />
          <Box
            sx={{
              ml: 3.5,
            }}
          >
            {contact.companyName ? (
              contact.companyName
            ) : (
              <IntlMessages id='common.na' />
            )}
          </Box>
        </Box>

        <Box
          sx={{
              mb: { xs: 2, md: 3 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <BadgeIcon
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          />
          <Box
            sx={{
              ml: 3.5,
            }}
          >
            {(contact.employerId) ? (
              (contact.employerId)
            ) : (
              <IntlMessages id='common.na' />
            )}
          </Box>
        </Box>

          {/*newly added ids and info */}
          <Box
              sx={{
                  mb: { xs: 2, md: 3 },
                  display: 'flex',
                  alignItems: 'center',
              }}
          >
              Amazon Seller ID
              <Box sx={{ ml: 3.5, }}>
                  {(contact.amazonSellerID) ? (
                      (contact.amazonSellerID)
                  ) : (
                      <IntlMessages id='common.na' />
                  )}
              </Box>
          </Box>

          <Box
              sx={{
                  mb: { xs: 2, md: 3 },
                  display: 'flex',
                  alignItems: 'center',
              }}
          >
              Walmart Store Link
              <Box sx={{ ml: 3.5, }}>
                  {(contact.walmartStoreLink) ? (
                      <a href={contact.walmartStoreLink} target="_blank" rel="noreferrer" > Open </a>
                  ) : (
                      <IntlMessages id='common.na' />
                  )}
              </Box>
          </Box>
          <Box
              sx={{
                  mb: { xs: 2, md: 3 },
                  display: 'flex',
                  alignItems: 'center',
              }}
          >
              Ebay Store Link
              <Box sx={{ ml: 3.5, }}>
                  {(contact.ebayStorefrontLink) ? (
                      <a href={contact.ebayStorefrontLink} target="_blank" rel="noreferrer" > Open </a>
                  ) : (
                      <IntlMessages id='common.na' />
                  )}
              </Box>
          </Box>
          <Box
              sx={{
                  mb: { xs: 2, md: 3 },
                  display: 'flex',
                  alignItems: 'center',
              }}
          >
              Website Link
              <Box sx={{ ml: 3.5, }}>
                  {(contact.websiteLink) ? (
                      <a href={contact.websiteLink} target="_blank" rel="noreferrer" > Open </a>
                  ) : (
                      <IntlMessages id='common.na' />
                  )}
              </Box>
          </Box>

          <Box
              sx={{
                  mb: { xs: 2, md: 3 },
                  display: 'flex',
                  alignItems: 'center',
              }}
          >
              Selling Somewhere Else
              <Box sx={{ ml: 3.5, }}>
                  {(contact.sellingSomewhereElse) ? (
                      (contact.sellingSomewhereElse)
                  ) : (
                      <IntlMessages id='common.na' />
                  )}
              </Box>
          </Box>
      </div>
    </Box>
  );
};

export default PersonalDetails;
