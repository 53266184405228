import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@crema/components/AppTable/TableHeader';

type Props = {
    keys: any[];
};
const TableHeading = ({keys = []}: Props) => {
    const noKeysFound = [
        "Credential ID",
        "Name",
        "Email",
        "Company Name",
        "State",
        "City",
        "Zip Code",
        "Address",
        "Phone Number",
        "Employer ID",
        "Seller Permit",
        "Hear About Us",
        "Selling Platform",
        "Status",
        "Actions"
    ]
  return (
    <TableHeader>
        {keys.length > 0 ? (
            <>{keys.map((key) => (<><TableCell>{key}</TableCell></>))}</>
        ) : (<>{noKeysFound.map((key) => (<><TableCell>{key}</TableCell></>))}</>)}
    </TableHeader>
  );
};

export default TableHeading;
