import React, {useEffect, useMemo, useState} from 'react';
import AppGridContainer from '@crema/components/AppGridContainer';
import { Fonts } from '@crema/constants/AppEnums';
import {Box, Button, FormControlLabel, Grid, Hidden, Slide, Switch, TextField} from '@mui/material';
import AppScrollbar from '@crema/components/AppScrollbar';
import AppCard from '@crema/components/AppCard';
import jwtAxios, {setAuthToken} from "../../../@crema/services/auth/jwt-auth";
import {openSnackbar} from "../../../toolkit/actions/snackbar";
import {useDispatch} from "react-redux";
import CustomSnackbar from "../../snackbar";
import AppsHeader from "../../../@crema/components/AppsContainer/AppsHeader";
import AppSearchBar from "../../../@crema/components/AppSearchBar";
import AppsPagination from "../../../@crema/components/AppsPagination";
import AppsContent from "../../../@crema/components/AppsContainer/AppsContent";
import ListingTable from "./Listing/ListingTable";

function Promocode() {
    const [formData, setFormData] = useState({
        promocodeName: '',
        offAmount: '',
        isPercentage: true // Default value
    });
    const [errors, setErrors] = useState({
        promocodeName: '',
        offAmount: ''
    });
    const [page, setPage] = useState(0);
    const [promocodeList, setPromocodeList] = useState([]);
    const [search, setSearch] = useState("");
    const promocodes = useMemo(() => {
        return promocodeList
            .filter(cats => cats.promocodeName.toLowerCase().includes(search.toLowerCase()))
            .map(cats => ({ "Promocode Name": cats.promocodeName, "OFF Amount": cats.offAmount, "Ammount in": cats.isPercentage ? "Percentage" : "Dollers", _id: cats._id }));
    }, [promocodeList, search]);
    const [promocodeID, setPromocodeID] = useState('');
    const [update, setUpdate] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: newValue
        });

        setErrors({
            ...errors,
            [name]: ''
        });

    };
    const updateTable = () => {

        jwtAxios.get( 'promocode' ).then((response) => {
            setPromocodeList(response.data);
            setLoadingTable(false)
            console.log("loading the promocode list", response.data)
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formData.promocodeName || !formData.offAmount) {
            setErrors({
                promocodeName: !formData.promocodeName ? 'Promocode Name is required' : '',
                offAmount: !formData.offAmount ? 'Off Amount is required' : ''
            });
            dispatch(openSnackbar('Please fill in all fields', 'error'));
            return;
        }
        // Handle form submission here using formData state
        console.log(formData);
        try {

            let url = update ? `promocode/${promocodeID}` : 'promocode'
            let action = update ? 'put' : 'post'
            const response = await jwtAxios[action](url, formData)


            console.log(response)
            updateTable()
            setFormData({
                promocodeName: '',
                offAmount: '',
                isPercentage: true // Default value
            })
            let messsage = update ? 'Promocode Updated successfully' : 'Promocode saved successfully'

            dispatch(openSnackbar(messsage, 'success')); // Replace with your message and type
        } catch (e) {
            dispatch(openSnackbar(e.response.data.message, 'error')); // Replace with your message and type
            console.log(e, "errorroororroororooor")
        }
    };

    useEffect(() => {
        updateTable()
    }, []);

    const onPageChange = (e) => {
        console.log(e)
    }

    const delete_fun = (data) => {
        console.log(data)
    }
    const edit_fun = (data) => {
        setPromocodeID(data._id)
        setFormData({
            promocodeName: data["Promocode Name"],
            offAmount: data["OFF Amount"],
            isPercentage: data["Ammount in"] === "Percentage", // Default value
            _id: data._id
        })
        setErrors({
            promocodeName: '',
            offAmount: ''
        })
        setUpdate(true)
        console.log(data)
    }

    return (
        <>

            <Box
                component='h2'
                sx={{
                    fontSize: 16,
                    color: 'text.primary',
                    fontWeight: Fonts.SEMI_BOLD,
                    mb: {
                        xs: 2,
                        lg: 4,
                    },
                }}
            >
                {'Promocode'}
            </Box>
            <Slide direction='right' in mountOnEnter unmountOnExit>
                <Grid item xs={12} lg={8}>
                    <AppScrollbar style={{ height: 'auto', marginBottom: "6vh" }}>
                        <AppCard>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    className='mt-8 mb-16 mx-6'
                                    label='Promocode Name'
                                    autoFocus
                                    id='promocodeName'
                                    name='promocodeName'
                                    variant='outlined'
                                    fullWidth
                                    value={formData.promocodeName}
                                    onChange={handleInputChange}
                                    error={!!errors.promocodeName}
                                    helperText={errors.promocodeName}
                                />

                                <br />
                                <br />

                                <TextField
                                    className='mt-8 mb-16 mx-6'
                                    label={`Off Amount in (${formData.isPercentage ? "%" : "$"})`}
                                    autoFocus
                                    type={"number"}
                                    id='offAmount'
                                    name='offAmount'
                                    variant='outlined'
                                    fullWidth
                                    value={formData.offAmount}
                                    onChange={handleInputChange}
                                    error={!!errors.offAmount}
                                    helperText={errors.offAmount}
                                />

                                <br />
                                <br />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData.isPercentage}
                                            onChange={handleInputChange}
                                            name='isPercentage'
                                        />
                                    }
                                    label='Off amount in percentage?'
                                />
                                <br />
                                <br />
                                <Button
                                    sx={{
                                        display: 'block',
                                        minWidth: 100,
                                    }}
                                    color='primary'
                                    variant='contained'
                                    type='submit'
                                >
                                    {update ? 'Update' : "Submit"}
                                </Button>
                            </form>
                        </AppCard>
                    </AppScrollbar>
                    <Slide direction='right' in mountOnEnter unmountOnExit>
                        <Grid item xs={12} lg={9}>
                            <AppCard
                                title={
                                    <AppsHeader>
                                        <Box
                                            display='flex'
                                            flexDirection='row'
                                            alignItems='center'
                                            width={1}
                                            justifyContent='space-between'
                                        >
                                            <AppSearchBar
                                                iconPosition='right'
                                                overlap={false}
                                                onChange={(event) =>
                                                    setSearch(event.target.value)
                                                }
                                                placeholder={"Search Here"}
                                            />
                                            <Box
                                                display='flex'
                                                flexDirection='row'
                                                alignItems='center'
                                                justifyContent='flex-end'
                                            >
                                                <Hidden smDown>
                                                    <AppsPagination
                                                        rowsPerPage={10}
                                                        count={10}
                                                        page={page}
                                                        onPageChange={onPageChange}
                                                    />
                                                </Hidden>
                                            </Box>
                                        </Box>
                                    </AppsHeader>
                                }
                                headerStyle={{ p: 0 }}
                                contentStyle={{ p: 0 }}
                            >
                                <AppsContent
                                    sx={{
                                        paddingTop: 2.5,
                                        paddingBottom: 2.5,
                                    }}
                                >
                                    <ListingTable delete_fun={delete_fun} edit_fun={edit_fun} tabledata={promocodes} loading={loadingTable} />
                                </AppsContent>
                                <Hidden smUp>
                                    <AppsPagination
                                        rowsPerPage={10}
                                        count={10}
                                        page={page}
                                        onPageChange={onPageChange}
                                    />
                                </Hidden>
                            </AppCard>
                        </Grid>
                    </Slide>
                </Grid>
            </Slide>
        </>
    );
}

export default Promocode;
