import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@crema/components/AppTable/TableHeader';
import { styled } from '@mui/system';

const TableHeading = () => {

    const StickyTableCell = styled(TableCell)(({ theme }) => ({
        position: 'sticky',
        left: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
        '&:nth-of-type(7)': {
            left: 'auto',
            right: 0,
            zIndex: 1,
        },
    }));
  return (
    <TableHeader>
        <StickyTableCell>Order ID</StickyTableCell>
      <TableCell align='left'>Order Date</TableCell>
      <TableCell align='left'>Product    - SKU    - Qty</TableCell>
      <TableCell align='left'>QTY Arrived</TableCell>
      <TableCell align='left'>Company</TableCell>
      <TableCell align='left'>Credential ID</TableCell>
      <TableCell align='left'>Price</TableCell>
      <TableCell align='left'>Credits Used</TableCell>
      <TableCell align='left' style={{ minWidth: 300 }}>Notes</TableCell>
        <StickyTableCell align='right'>Actions</StickyTableCell>
    </TableHeader>
  );
};

export default TableHeading;
