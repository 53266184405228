const initialState = {
    open: false,
    message: '',
    type: ''
};

const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'OPEN_SNACKBAR':
            return {
                ...state,
                open: true,
                message: action.payload.message,
                type: action.payload.type
            };
        case 'CLOSE_SNACKBAR':
            return {
                ...state,
                open: false
            };
        default:
            return state;
    }
};

export default snackbarReducer;
