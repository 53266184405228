import React, { useEffect, useMemo, useState } from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { Button, Hidden, TableRow, TextField } from '@mui/material';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

const AgentApprovals = () => {
    const { messages } = useIntl();
    const [orders, setRecentOrders] = useState([]);
    const [page, setPage] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [agentIds, setAgentIds] = useState({});

    const onPageChange = (value) => {
        setPage(value);
    };

    const fetchData = () => {
        let config = {
            method: 'get',
            url: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/api/agents',
            headers: {
                'apikey': 'c747b51cc1b18e6e9f4fdd72d6ada6c8',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        axios.request(config)
            .then((response) => {
                setTableData(response.data);
                setTotalOrders(response.data.length); // Assuming this is the total count
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchData();
    }, [page]);

    const handleAgentIdChange = (id, value) => {
        setAgentIds(prev => ({ ...prev, [id]: value }));
    };

    const approveAgent = (agent) => {
        if (window.confirm('Are you sure you want to approve the agent?')) {
            let data = JSON.stringify({
                "approvalStatus": "Approved",
                "agentId": agentIds[agent._id] || ''  // Get the agentId for this row
            });

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/api/agents/' + agent._id,
                headers: {
                    'apikey': 'c747b51cc1b18e6e9f4fdd72d6ada6c8',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    alert("Approved Successfully");
                    fetchData();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <AppsContainer title={'Agent Approvals'} fullView>
                <AppsHeader>
                    <Box display='flex' flexDirection='row' alignItems='center' width={1} justifyContent='space-between'>
                        <AppSearchBar
                            iconPosition='right'
                            overlap={false}
                            placeholder={"Search Here"}
                        />
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Hidden smDown>
                                <AppsPagination
                                    rowsPerPage={10}
                                    count={totalOrders}
                                    page={page}
                                    onPageChange={onPageChange}
                                />
                            </Hidden>
                        </Box>
                    </Box>
                </AppsHeader>

                <AppsContent sx={{ paddingTop: 2.5, paddingBottom: 2.5 }}>
                    <Table stickyHeader className='table'>
                        <TableHead>
                            <TableCell>Name</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>IMEI</TableCell>
                            <TableCell>Agent ID</TableCell>
                            <TableCell>Action</TableCell>
                        </TableHead>
                        <TableBody>
                            {tableData.map((data) => (
                                data.approvalStatus === "Pending" || data.approvalStatus !== "Approved" ? (
                                    <TableRow key={data._id}>
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell>{data.number}</TableCell>
                                        <TableCell>{data.email}</TableCell>
                                        <TableCell>{data.imei}</TableCell>
                                        <TableCell>
                                            <TextField
                                                label='Agent ID'
                                                variant='outlined'
                                                fullWidth
                                                value={agentIds[data._id] || ''}
                                                onChange={(e) => handleAgentIdChange(data._id, e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{ display: 'block', minWidth: 100 }}
                                                color='success'
                                                variant='contained'
                                                type='submit'
                                                onClick={() => approveAgent(data)}
                                            >
                                                Approve
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ) : null
                            ))}
                        </TableBody>
                    </Table>
                </AppsContent>

                <Hidden smUp>
                    <AppsPagination
                        rowsPerPage={10}
                        count={totalOrders}
                        page={page}
                        onPageChange={onPageChange}
                    />
                </Hidden>
            </AppsContainer>
            <AppInfoView />
        </>
    );
};

export default AgentApprovals;
