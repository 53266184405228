import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import jwtAxios from "../../../@crema/services/auth/jwt-auth";

function CreateUser() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        roles: []
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            const updatedRoles = checked
                ? [...formData.roles, value]
                : formData.roles.filter(role => role !== value);
            setFormData({ ...formData, roles: updatedRoles });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await jwtAxios.post('/registerWebUser', formData);
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box component="div" sx={{ marginTop: 2 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={formData.name}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={formData.password}
                    onChange={handleChange}
                />
                <Grid container spacing={2}>
                    {['Orders', 'Warehouse', 'Credits', 'Confiscation', 'Masters', 'Site Images', 'Reports', 'Dashboard'].map(role => (
                        <Grid item key={role}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={role}
                                        onChange={handleChange}
                                        checked={formData.roles.includes(role)}
                                    />
                                }
                                label={role}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Submit
                </Button>
            </Box>
        </form>
    );
}

export default CreateUser;
